import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { getNewsSectionWithDefault } from './utils';

export const newsRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  let splittedPathname = splitPathname(pathname);
  let newsSection = splittedPathname.at(2);
  if (newsSection === 'home') {
    splittedPathname = [...splittedPathname.slice(0, 2), '', ...splittedPathname.slice(3)];
    update.location.pathname = `/${splittedPathname.join('/')}`;
    newsSection = '';
  }
  const validNewsSection = getNewsSectionWithDefault(newsSection);
  if (validNewsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validNewsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  return update;
};
