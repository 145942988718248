import { Locale } from '@goldwasserexchange/i18n';
import * as React from 'react';
import { useAtomValue, atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { pathnameUrlNewsType, pathnameUrlLanguageAtom } from '../../../../../../history';
import { NormalLanguageToolbarDropDownListElementLink } from '../normal';
import { NewsArticleLanguageToolbarDropDownListElementLink } from './components';
import { NewsHomeAndSearchLanguageToolbarDropDownListElementLink } from './components/homeAndSearch';

const newsLanguageToolbarDropDownListElementLinkAtom = (locale: Locale) => atom<'article' | 'homeAndSearch' | 'other'>((get) => {
  const article = get(pathnameUrlNewsType);
  const currentLocale = get(pathnameUrlLanguageAtom);
  if (article === '' || article === 'search') {
    return 'homeAndSearch';
  }
  if (article === 'article' && locale !== currentLocale) {
    return 'article';
  }
  return 'other';
});

const newsLanguageToolbarDropDownListElementLinkAtomFamily = atomFamily((locale: Locale) => newsLanguageToolbarDropDownListElementLinkAtom(locale));

export const NewsLanguageToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  locale: Locale,
}>) => {
  const {
    locale,
    children,
  } = props;
  const toolbarType = useAtomValue(newsLanguageToolbarDropDownListElementLinkAtomFamily(locale));
  switch (toolbarType) {
    case 'article':
      return (
        <NewsArticleLanguageToolbarDropDownListElementLink locale={locale}>
          {children}
        </NewsArticleLanguageToolbarDropDownListElementLink>
      );
    case 'homeAndSearch':
      return (
        <NewsHomeAndSearchLanguageToolbarDropDownListElementLink locale={locale}>
          {children}
        </NewsHomeAndSearchLanguageToolbarDropDownListElementLink>
      );
    default:
      return (
        <NormalLanguageToolbarDropDownListElementLink locale={locale}>
          {children}
        </NormalLanguageToolbarDropDownListElementLink>
      );
  }
};
