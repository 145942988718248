import { Update } from 'history';
import { splitPathname } from '../../../../utils';
import { getValidOrdersSection } from './utils';
import { OrdersSectionRouteDefinition } from './router';

export const accountsOrdersRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const ordersSection = splittedPathname.at(3);
  const validOrdersSection = getValidOrdersSection(ordersSection);
  const {
    importFn,
  } = OrdersSectionRouteDefinition[validOrdersSection];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
};
