import { DEFAULT_RATE_PRODUCT_TYPE, RATE_PRODUCT_TYPES, RateProductType } from './constants';

export const isValidAccountsRatesProductType = (section: string): section is RateProductType => RATE_PRODUCT_TYPES.includes(section as RateProductType);

export const parseValidAccountsRatesProductTypeWithDefault = (section: string | (string | null)[] | null) => {
  if (section == null || Array.isArray(section) || !isValidAccountsRatesProductType(section)) {
    return DEFAULT_RATE_PRODUCT_TYPE;
  }
  return section;
};
