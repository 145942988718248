import { BONDS_SECTIONS, BondsSection } from './constants';

export const isValidBondsSection = (section: string | BondsSection): section is BondsSection => BONDS_SECTIONS.includes(section as BondsSection);

export const getValidBondSection = (subSection: string | undefined): BondsSection | 'not-found' => {
  if (subSection === '' || subSection == null) {
    return '';
  }
  if (!isValidBondsSection(subSection)) {
    return 'not-found';
  }
  return subSection;
};
