import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { bondsRouteDefinition } from './router';
import { getValidBondSection } from './utils';

export const bondsRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const bondsSection = splittedPathname.at(2);
  const validBondsSection = getValidBondSection(bondsSection);
  const {
    importFn,
  } = bondsRouteDefinition[validBondsSection];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
};
