import { NOT_FOUND, ValidSections } from './constants';

export const sectionsRoutesDefinition = {
  [ValidSections.HOME]: {
    importFn: () => import('../../../Components/Sections/home/component'),
  },
  [ValidSections.ONLINE]: {
    importFn: () => import('../../../Components/Sections/online/component'),
  },
  [ValidSections.NEWS]: {
    importFn: () => import('../../../Components/Sections/news/component'),
  },
  [ValidSections.BONDS]: {
    importFn: () => import('../../../Components/Sections/bonds/component'),
  },
  [ValidSections.CONTACT]: {
    importFn: () => import('../../../Components/Sections/contact/component'),
  },
  [ValidSections.ONBOARDING]: {
    importFn: () => import('../../../Components/Sections/onboarding/component'),
  },
  [ValidSections.LEGAL]: {
    importFn: () => import('../../../Components/Sections/legal/component'),
  },
  [ValidSections.GLOBAL_PLUS_ACCOUNT]: {
    importFn: () => import('../../../Components/Sections/globalPlusAccount/component'),
  },
  [ValidSections.GOLD_ACCOUNT]: {
    importFn: () => import('../../../Components/Sections/goldAccount/component'),
  },
  [ValidSections.PREMIUM_ACCOUNT]: {
    importFn: () => import('../../../Components/Sections/premiumAccount/component'),
  },
  [ValidSections.SECURITY]: {
    importFn: () => import('../../../Components/Sections/security/components'),
  },
  [ValidSections.SIGN_UP]: {
    importFn: () => import('../../../Components/Sections/signUp/component'),
  },
  [ValidSections.UPLOAD]: {
    importFn: () => import('../../../Components/Sections/upload/component'),
  },
  [ValidSections.ORDER]: {
    importFn: () => import('../../../Components/Sections/order/component'),
  },
  [NOT_FOUND]: {
    importFn: () => import('../../../Components/Sections/not-found/component'),
  },
};
