export var Colors;
(function (Colors) {
    Colors["normal"] = "#000000";
    Colors["logoBlue"] = "#78bde8";
    Colors["logoBlueDarker"] = "#2999de";
    Colors["logoText"] = "#21346c";
    Colors["logoYellow"] = "#f6cb47";
    Colors["primary"] = "#3994cf";
    Colors["primaryDarker"] = "#5baddf";
    Colors["primaryLigther"] = "#a2cff0";
    Colors["primaryTranslucid"] = "#ecf7fd";
    Colors["primaryShaded"] = "#2d87c1";
    Colors["fadeAccent"] = "#91bce6";
    Colors["accent"] = "#1f5a96";
    Colors["accentShaded"] = "#0f4586";
    Colors["evenDarkerFade"] = "#bce2f9";
    Colors["fade"] = "#e3f2fb";
    Colors["fadeDarker"] = "#C5DBF2";
    Colors["fadeOnPrimary"] = "#75BEE9";
    Colors["placeHolderFade"] = "#c9d9e9";
    Colors["fadeGradient"] = "#d4e9f8";
    Colors["warning"] = "#f7ca38";
    Colors["danger"] = "#d9534f";
    Colors["dangerLigther"] = "#f4cecd";
    Colors["success"] = "#2ecc40";
    Colors["successLigther"] = "#a7ebaf";
    Colors["disabled"] = "#dddddd";
    Colors["otherDisabled"] = "#dcdcdc";
    Colors["inverted"] = "#ffffff";
    Colors["inherit"] = "inherit";
    Colors["transparent"] = "transparent";
    Colors["link"] = "#3294d1";
    Colors["buttonBorderGrey"] = "#cccccc";
    Colors["colorScale0"] = "#21346b";
    Colors["colorScale1"] = "#245797";
    Colors["colorScale2"] = "#2a78c0";
    Colors["colorScale3"] = "#3294d1";
    Colors["colorScale4"] = "#76beeb";
    Colors["colorScale5"] = "#b9dbf4";
    Colors["colorScale6"] = "#dff1ff";
    Colors["grey"] = "#f1f1f1";
    Colors["greyLigther"] = "#d4d4d4";
    Colors["greyDarker"] = "#606060";
    Colors["greyDarkest"] = "#666666";
    Colors["skrimShade"] = "rgba(0,0,0,0.7)";
})(Colors || (Colors = {}));
export var PDFColors;
(function (PDFColors) {
    PDFColors["darkGrey"] = "#393536";
    PDFColors["darkBlue"] = "#2a4b9b";
    PDFColors["blue"] = "#4d93d6";
    PDFColors["lightBlue"] = "#81c3ed";
    PDFColors["ultraLightBlue"] = "#bfe0f6";
    PDFColors["weakBlue"] = "#e6f2fc";
})(PDFColors || (PDFColors = {}));
