import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useBondsBondTransformApi } from '../api';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { makeBondFullNameTransformFn } from '../../../shared/bondFullName';

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useBondsBondFullName = (props?: {
  maxSIssuerName1Length?: number,
}) => {
  const {
    maxSIssuerName1Length,
  } = props ?? {};
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const transformFn = makeBondFullNameTransformFn({
    intRateFormatter,
    locale,
    maxSIssuerName1Length,
  });
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
