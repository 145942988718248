import { useCallback } from 'react';
import { BondResponse } from '../../type';
import { useBondsBondTransformApi } from '../api';

const makeTransformFn = (minEur?: number) => (bond: BondResponse | undefined) => {
  if (bond == null) {
    return null;
  }
  const exRate = bond?.['EXRATE_HIS.EXRATE_GES'];
  const qtMin = bond?.['S_MARKET.QT_MIN'];
  const quotMul = bond?.['S_MARKET.QUOT_MULT'];
  const coupure = Math.max(1000, quotMul ?? qtMin);
  const minCurrency = (minEur ?? 10_000) / (exRate ?? 1);
  const min = Math.max(qtMin ?? 0, minCurrency);
  const rangeMin = Math.floor(min / coupure) * coupure;
  return rangeMin;
};

export const useBondsBondMinQuantity = (minEur?: number) => {
  const transformFn = useCallback(
    makeTransformFn(minEur),
    [minEur],
  );
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
