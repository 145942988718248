import { Trans } from '@lingui/macro';
import * as React from 'react';
import { usePersonAccountsGesTypeShortcut } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const PersonsAccountsGesTypeShortcut = (props: {
  withAccountText?: boolean,
}) => {
  const {
    withAccountText = true,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = usePersonAccountsGesTypeShortcut();
  if (withAccountText === false) {
    return (
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading}
        error={error}
        loadingValue="----"
      />
    );
  }
  return (
    <Trans>
      Compte&#32;
      <ActorApiStringDisplay
        data={data}
        isLoading={isLoading}
        error={error}
        loadingValue="----"
      />
    </Trans>
  );
};
