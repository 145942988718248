export enum ValidSections {
  HOME = '',
  ONLINE = 'accounts',
  NEWS = 'news',
  BONDS = 'bonds',
  CONTACT = 'contact',
  ONBOARDING = 'onboarding',
  LEGAL = 'legal',
  GOLD_ACCOUNT = 'gold',
  PREMIUM_ACCOUNT = 'premium',
  GLOBAL_PLUS_ACCOUNT = 'global-plus',
  SECURITY = 'profile',
  SIGN_UP = 'signup',
  UPLOAD = 'upload',
  ORDER = 'order',
}

export const VALID_SECTIONS = [
  ValidSections.HOME,
  ValidSections.BONDS,
  ValidSections.GOLD_ACCOUNT,
  ValidSections.PREMIUM_ACCOUNT,
  ValidSections.GLOBAL_PLUS_ACCOUNT,
  ValidSections.ONLINE,
  ValidSections.CONTACT,
  ValidSections.ONBOARDING,
  ValidSections.LEGAL,
  ValidSections.SECURITY,
  ValidSections.SIGN_UP,
  ValidSections.UPLOAD,
  ValidSections.NEWS,
  ValidSections.ORDER,
];

export const DEFAULT_SECTION = ValidSections.HOME;

export const NOT_FOUND = 'not-found';
