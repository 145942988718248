import { imageQuery } from '../../shared';

export const query = /* groq */`
*[
  _type == "featuredNews"
  && language == $locale
  && defined(currentFeaturedNews._ref)
  && currentFeaturedNews -> { publishDate }.publishDate.utc < now()
  && showOnHomePage == true
] {
  ...currentFeaturedNews -> {
    "locale": language,
    title,
    "date": publishDate.utc,
    "id": _id,
    image ${imageQuery},
  }
}[0]
` as const;
