import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { getValidAccountsSection } from './utils';
import { accountsHistoryRedirect } from './history';
import { accountsRatesRedirect } from './rates';
import { accountOrdersRedirect } from './orders';

export const accountsRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const accountsSection = splittedPathname.at(2);
  const validAccountsSection = getValidAccountsSection(accountsSection);
  if (validAccountsSection !== 'not-found' && validAccountsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validAccountsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  switch (validAccountsSection) {
    case 'orders':
      return accountOrdersRedirect(update);
    case 'history':
      return accountsHistoryRedirect(update);
    case 'rates':
      return accountsRatesRedirect(update);
    default:
      return update;
  }
};
