import { Locale } from '@goldwasserexchange/i18n';
import * as React from 'react';
import { useAtomValue } from 'jotai';
import { pathnameUrlSectionAtom, ValidSections } from '../../../../../../history';
import { NewsLanguageToolbarDropDownListElementLink } from '../news';
import { NormalLanguageToolbarDropDownListElementLink } from '../normal';
import { OrderLanguageToolbarDropDownListElementLink } from '../order/component';

export const LanguageToolbarDropDownListElementLinkSwitcher = (props: React.PropsWithChildren<{
  language: Locale,
}>) => {
  const {
    language,
    children,
  } = props;
  const section = useAtomValue(pathnameUrlSectionAtom);
  if (section === ValidSections.NEWS) {
    return (
      <NewsLanguageToolbarDropDownListElementLink
        locale={language}
      >
        {children}
      </NewsLanguageToolbarDropDownListElementLink>
    );
  }
  if (section === ValidSections.ORDER) {
    return (
      <OrderLanguageToolbarDropDownListElementLink
        locale={language}
      >
        {children}
      </OrderLanguageToolbarDropDownListElementLink>
    );
  }
  return (
    <NormalLanguageToolbarDropDownListElementLink
      locale={language}
    >
      {children}
    </NormalLanguageToolbarDropDownListElementLink>
  );
};
