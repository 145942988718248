import {
  Update,
} from 'history';
import { splitPathname } from '../../utils';
import {
  getLanguageRedirectValue,
  getStorageLanguage,
  isLocale,
  setStorageLanguage,
} from './utils';

export const languageRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  let splittedPathname = splitPathname(pathname);
  let language = splittedPathname.at(0);
  if (language !== 'fr-BE' && language !== 'nl-BE' && language != null && language.length !== 2) {
    splittedPathname = ['', ...splittedPathname];
    language = '';
  }
  const languageRedirectValue = getLanguageRedirectValue(
    language,
  );
  if (languageRedirectValue != null) {
    language = languageRedirectValue;
    update.location.pathname = `/${[language, ...splittedPathname.slice(1)].join('/')}`;
  }
  language = language === '' || language == null ? getStorageLanguage() : language;
  if (language !== splittedPathname.at(0)) {
    update.location.pathname = `/${[language, ...splittedPathname.slice(1)].join('/')}`;
  }
  if (language !== getStorageLanguage() && isLocale(language)) {
    setStorageLanguage(language);
  }
  return update;
};
