export const OrdersSectionRouteDefinition: Record<'' | 'new' | 'not-found', any> = {
  '': {
    importFn: () => import('../../../../../Components/Sections/online/sections/orders/sections/list/component'),
  },
  new: {
    importFn: () => import('../../../../../Components/Sections/online/sections/orders/sections/new/component'),
  },
  'not-found': {
    importFn: () => import('../../../../../Components/Sections/online/sections/orders/sections/notFound/component'),
  },
};
