import { Update } from 'history';
import { splitPathname } from '../../../../utils';
import { getValidOrdersSection } from './utils';

export const accountOrdersRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const ordersSection = splittedPathname.at(3);
  const validOrdersSection = getValidOrdersSection(ordersSection);
  if (validOrdersSection !== 'not-found' && validOrdersSection !== splittedPathname.at(3)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 3), validOrdersSection, ...splittedPathname.slice(4)].filter((d) => d !== '').join('/')}`;
  }
  return update;
};
