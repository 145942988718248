import { Update } from 'history';
import { onboardingRoutesDefinition } from './router';
import { splitPathname } from '../../../utils';
import { getValidOnboardingSection } from './utils';

export const onboardingsRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const onboardingSection = splittedPathname.at(2);
  const validBondsSection = getValidOnboardingSection(onboardingSection);
  const {
    importFn,
  } = onboardingRoutesDefinition[validBondsSection];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
};
