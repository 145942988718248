/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
import { useInputContainerRef } from '../../../../../OUI/Inputs';

export const defaultDomInputContainerStyle: ouiStyle.InStyle = {
  container: ouiStyle.Constants.Container.sm,
  marginHorizontal: 'auto',
};

export function DefaultDomInputContainer(props: React.PropsWithChildren<{
  widthLines?: ouiStyle.InStyleWithMediaQueries['widthLines'],
}>) {
  const {
    widthLines = defaultDomInputContainerStyle.widthLines,
    children,
  } = props;
  const defaultDomInputContainerCss = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultDomInputContainerStyle,
    widthLines,
  })), [widthLines]);
  const inputContainerRef = useInputContainerRef();
  return (
    <section ref={inputContainerRef} css={defaultDomInputContainerCss}>
      {children}
    </section>
  );
}
