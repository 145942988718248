import * as React from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { Locale } from '@goldwasserexchange/i18n';
import { useAtomValue } from 'jotai';
import NavBarLink from '../navBarLink/navBarLink';
import NavBarLinkIconContainer from '../navBarLinkIconContainer';
import NavBarLinkTextContainer from '../navBarLinkTextContainer';
import NavBarListElement from '../navBarListElement/listElement';
import NavBarLinkSelectedBar from '../navBarLinkSelectedBar';
import { ValidSections, pathnameUrlLanguageAtom } from '../../../../history';
import { NavBarIcons, navBarIcons } from './icons';

const NavBarOnlineLink = (props: React.PropsWithChildren<{
  section: ValidSections,
  language: Locale,
}>) => {
  const {
    section,
    language,
    children,
  } = props;
  return (
    <NavBarLink
      to={`/${language}/${section}/details`}
    >
      {children}
    </NavBarLink>
  );
};

const NavBarRouterLink = (props: React.PropsWithChildren<{ section: ValidSections }>) => {
  const {
    section,
    children,
  } = props;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  if (section === ValidSections.ONLINE) {
    return (
      <NavBarOnlineLink
        section={section}
        language={language}
      >
        {children}
      </NavBarOnlineLink>
    );
  }
  return (
    <NavBarLink
      to={`/${language}/${section}`}
    >
      {children}
    </NavBarLink>
  );
};

const NavBarRouterLinkElement = (props: React.PropsWithChildren<Pick<ouiBase.A.BaseLinkProps, 'replace'> & { section: ValidSections, iconName: keyof NavBarIcons }>) => {
  const {
    section,
    iconName,
    children,
  } = props;
  const Component = navBarIcons[iconName];
  return (
    <NavBarListElement>
      <NavBarRouterLink
        section={section}
      >
        <NavBarLinkIconContainer>
          <Component />
        </NavBarLinkIconContainer>
        <NavBarLinkTextContainer>
          {children}
        </NavBarLinkTextContainer>
        <NavBarLinkSelectedBar section={section} />
      </NavBarRouterLink>
    </NavBarListElement>
  );
};

export const NavBarLinkElement = (
  props: React.PropsWithChildren<{
    section: ValidSections,
    iconName: keyof NavBarIcons,
  }>,
): JSX.Element => {
  const { section, iconName, children } = props;
  return (
    <NavBarRouterLinkElement
      section={section}
      iconName={iconName}
    >
      {children}
    </NavBarRouterLinkElement>
  );
};

export default NavBarLinkElement;
