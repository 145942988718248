import ouiBase from '@goldwasserexchange/oui';
import { STypeGrpIds } from '../../../../../shared/utils/sTypeGrpIds';
import { useAccountOrderFieldTransfomApiByOpbFordId } from '../api';
import { AccountsOrder } from '../../type';

const bondSTypeGrpIds: STypeGrpIds[] = [
  STypeGrpIds.OBLIGATIONS,
  STypeGrpIds.OBLIGATIONS_SUB,
  STypeGrpIds.OBLIGATIONS_PERP,
];

const isBond = (sTypeGrpId: AccountsOrder['S_TYPE_GRP.ID'] | undefined) => {
  if (sTypeGrpId == null) {
    return undefined;
  }
  return bondSTypeGrpIds.includes(sTypeGrpId);
};

export const useAccountOrderStypeGrpIsBond = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldTransfomApiByOpbFordId({
    opbFordDId,
    field: 'S_TYPE_GRP.ID',
    transformFunction: isBond,
  });
  return response;
};
