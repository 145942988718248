import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId, useAccountOrderTransformApiByOpbFordId } from '../api';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useCallback } from 'react';
import { AccountsOrder } from '../../type';
import { useLingui } from '@lingui/react';
import { mcOpbLimiteMessageDescriptions } from './mcOpbLimite';

export const useAccountOrderOpbFordPrice = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'OPB_FORD.PRICE',
  });
  return response;
};

const format: Partial<Intl.NumberFormatOptions> = {
  style: 'percent',
};

export const useAccountOrderTypeAndPriceText = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const numberFormatter = useNumberFormatter({
    format,
    divider: 100,
  });
  const {
    i18n,
  } = useLingui();
  const transformFunction = useCallback(
    (accountOrder: AccountsOrder | undefined) => {
      if (accountOrder == null) {
        return undefined;
      }
      const mcOpbLimite = accountOrder['OPB_FORD.MC_OPB_LIMITE'];
      const message = mcOpbLimiteMessageDescriptions[mcOpbLimite];
      const type = `${message != null ? `${i18n._(message)} ` : ''}`;
      const opbFordPrice = accountOrder['OPB_FORD.PRICE'];
      const price = mcOpbLimite === 5302 ? `(${numberFormatter(opbFordPrice)})` : '';
      return `${type}${price}`;
    },
    [numberFormatter, i18n],
  );
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction,
  });
  return response;
};
