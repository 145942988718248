import { DEFAULT_ORDERS_SECTION, ORDERS_SECTIONS } from './constants';

export const isValidOrdersSections = (section: string): section is '' | 'new' => ORDERS_SECTIONS.includes(section);

export const getValidOrdersSection = (section: string | undefined): '' | 'new' | 'not-found' => {
  if (section == null) {
    return DEFAULT_ORDERS_SECTION;
  }
  if (!isValidOrdersSections(section)) {
    return 'not-found';
  }
  return section;
};
