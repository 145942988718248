import * as React from 'react';
import ouiDom from '@goldwasserexchange/oui-dom';
import { Trans } from '@lingui/macro';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiStyle from '@goldwasserexchange/oui-style';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TextInput } from '../../../../../../OUI-dom/Inputs';
import { InputCodeSubmitButton } from './submitButton';
import { RememberDeviceInput } from './rememberDeviceInput';
import { CenteredButtonContainer } from '../../../../../CenteredButtonContainer';
import { InputCodeForm } from './form';
import { DefaultTextInputComponent } from '../../../../../../OUI-dom/Inputs/components/text/components';

export const CodeInputContent = (props: {
  textAlign?: ouiStyle.InStyle['textAlign'],
  saveDevice?: boolean,
  confirm?: boolean,
}) => {
  const {
    saveDevice = true,
    confirm = false,
    textAlign,
  } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <InputCodeForm>
      {!confirm
        ? (
          <ouiDom.P.P
            textAlign={textAlign}
          >
            <Trans>
              Entrez le code de sécurité reçu par SMS :
            </Trans>
          </ouiDom.P.P>
        )
        : (
          <ouiDom.P.P
            textAlign={textAlign}
          >
            <Trans>
              Entrez le code de confirmation reçu par SMS :
            </Trans>
          </ouiDom.P.P>
        )}
      <TextInput
        fieldPath={valueState.auth.mfaCode.path.join('.')}
        autocomplete={false}
      >
        <TopModel name="TextInputLabelContent">
          <Trans>
            Code de sécurité
          </Trans>
        </TopModel>
        <TopModel
          name="TextInputComponent"
          inputMode="decimal"
          pattern="\d*"
        >
          {DefaultTextInputComponent}
        </TopModel>
      </TextInput>
      {saveDevice ? <RememberDeviceInput /> : null}
      <CenteredButtonContainer>
        <InputCodeSubmitButton />
      </CenteredButtonContainer>
    </InputCodeForm>
  );
};
