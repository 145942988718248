import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import * as machine from '../../../machine';

export const ToolbarDropDownListElementLink = (props: React.PropsWithChildren<{
  to: string,
  textTransform: ouiStyle.InStyle['textTransform'],
  textAlign: ouiStyle.InStyle['textAlign'],
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
}>) => {
  const {
    to,
    onClick: parentOnClick,
    textTransform,
    textAlign,
    children,
  } = props;
  const sendLinkClick = machine.useLinkClick();
  const onClick = React.useCallback((e) => {
    sendLinkClick();
    if (parentOnClick) {
      parentOnClick(e);
    }
  }, [parentOnClick, sendLinkClick]);
  return (
    <ouiDom.A.A
      to={to}
      display={ouiStyle.Constants.DisplayProp.block}
      fontSize="m"
      textAlign={textAlign}
      color={ouiStyle.Constants.Colors.inverted}
      textTransform={textTransform}
      onClick={onClick}
      widthLines="full"
      paddingHorizontal="simple"
    >
      {children}
    </ouiDom.A.A>
  );
};
