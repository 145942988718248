import * as React from 'react';
import { useAccountOrderStatusText } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const AccountOrderStatusText = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountOrderStatusText();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
