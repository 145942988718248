import { Trans } from '@lingui/macro';
import * as React from 'react';
import { CommunicationMethodsExplanationContainer } from './components';

export const CommunicationMethodsExplanation = () => (
  <CommunicationMethodsExplanationContainer>
    <Trans>
      <p>
        Vous êtes désigné comme la personne de contact.
      </p>
      <p>
        La personne de contact est celle à qui Goldwasser Exchange adressera toute communication concernant le ou les comptes ouverts qui ne serait pas délivrée par notre site internet dans le cadre de la présente relation
      </p>
    </Trans>
  </CommunicationMethodsExplanationContainer>
);
