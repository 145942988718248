import { Update } from 'history';
import qs from 'query-string';
import { isValidAccountsRatesProductType } from './utils';
import { DEFAULT_RATE_PRODUCT_TYPE } from './constants';

export const accountsRatesRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const { search } = location;
  const searchParsed = qs.parse(search);
  const { product } = searchParsed;
  if (product == null) {
    update.location.search = qs.stringify({ ...searchParsed, product: DEFAULT_RATE_PRODUCT_TYPE });
  } else if (Array.isArray(product)) {
    update.location.search = qs.stringify({ ...searchParsed, product: DEFAULT_RATE_PRODUCT_TYPE });
  } else if (!isValidAccountsRatesProductType(product)) {
    update.location.search = qs.stringify({ ...searchParsed, product: DEFAULT_RATE_PRODUCT_TYPE });
  }
  return update;
};
