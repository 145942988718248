import { Update } from 'history';
import { splitPathname } from '../../utils';
import { accountsRedirect } from './accounts';
import { newsRedirect } from './news';
import { bondsRedirect } from './bonds';
import { onboardingRedirect } from './onboarding';
import { getValidSection } from './utils';

export const sectionsRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  let splittedPathname = splitPathname(pathname);
  let section = splittedPathname.at(1);
  if (section === 'home') {
    splittedPathname = [splittedPathname.at(0) as string, '', ...splittedPathname.slice(2)];
    update.location.pathname = `/${splittedPathname.join('/')}`;
    section = '';
  }
  const validSection = getValidSection(section);
  if (validSection !== 'not-found' && splittedPathname.at(1) !== validSection) {
    update.location.pathname = `/${[splittedPathname.at(0), validSection, ...splittedPathname.slice(2)].join('/')}`;
  }
  switch (validSection) {
    case 'accounts':
      return accountsRedirect(update);
    case 'news':
      return newsRedirect(update);
    case 'bonds':
      return bondsRedirect(update);
    case 'onboarding':
      return onboardingRedirect(update);
    default:
      return update;
  }
};
