import ouiBase from '@goldwasserexchange/oui';
import { currencyIsEur } from '../../../../shared';
import { AccountPosition } from '../../type';
import { useAccountPositionTransformApiByGesEstimDId } from '../api';

const transformFunction = (accountPosition: AccountPosition | undefined) => (
  accountPosition
    ? currencyIsEur(accountPosition['C_CURRENCY.CODE'])
    : 'EUR'
);

export const useAccountPositionsCCurrencyCodeIsEur = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const acountPositionsCCurrencyCodeApiResponse = useAccountPositionTransformApiByGesEstimDId({
    gesEstimDId,
    transformFunction,
  });
  return acountPositionsCCurrencyCodeApiResponse;
};
