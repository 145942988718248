import { Update } from 'history';
import qs from 'query-string';
import { splitPathname } from '../../../../utils';
import { parseNumber, parsePositiveNumberFromQs } from './utils';

export const bondsDetailsRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const {
    location,
  } = update;
  const {
    search,
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const sAccIdFromPathname = splittedPathname.at(3)?.split('_')?.at(-1);
  const parsedSAccIdFromPathname = parseNumber(sAccIdFromPathname);
  if (parsedSAccIdFromPathname == null || parsedSAccIdFromPathname < 0) {
    // this is only there to support deprecated details url with search param
    const searchParsed = qs.parse(search);
    const {
      id,
      ...rest
    } = searchParsed;
    const parsedId = parsePositiveNumberFromQs(id);
    if (parsedId != null) {
      update.location.pathname = `/${[...splittedPathname.slice(0, 3), parsedId, ...splittedPathname.slice(4)].join('/')}`;
      update.location.search = qs.stringify(rest);
    }
  }
  return update;
};
