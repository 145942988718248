import { safeParse } from '@goldwasserexchange/utils';
import * as React from 'react';
import { isNil } from 'ramda';
import { useEffect } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { UncastedDataStructure } from '@goldwasserexchange/oblis-frontend-utils';
import { useHookstate } from '@hookstate/core';
import { atom, useAtomValue } from 'jotai';
import {
  useAuthMachineCognitoUser,
  useIsConnected,
} from '../../../Auth/context/hooks';
import { TAccIdContextProvider } from '../../../../actor/api/Services/shared';
import { getStorage } from '../../../../storage';
import { CognitoUserWithChallengeName } from '../../../Auth/type';
import { locationSearchParsedAtom, parsePositiveNumberFromQs } from '../../../../history';

export const getCognitoUserAccountIds = (cognitoUser: CognitoUserWithChallengeName | null): { accounts: number[], isLoading: boolean } => {
  if (!cognitoUser) {
    return { accounts: [], isLoading: true };
  }
  const userSession = cognitoUser.getSignInUserSession();
  if (!userSession) {
    return { accounts: [], isLoading: true };
  }

  const idToken = userSession.getIdToken();
  const {
    payload,
  } = idToken;
  const {
    accounts,
  } = payload;

  const parsedAccounts = safeParse(accounts);
  if (!parsedAccounts) {
    return { accounts: [], isLoading: false };
  }

  return { accounts: parsedAccounts, isLoading: false };
};

const getCognitoUserFirstAccountId = (accountIds: number[] | undefined) => {
  if (accountIds) {
    const firstAccountId = accountIds && accountIds[0] ? accountIds[0] : null;
    return firstAccountId;
  }
  return null;
};

export const useCognitoUserValidAccounts = () => {
  const cognitoUser = useAuthMachineCognitoUser();
  const { accounts } = getCognitoUserAccountIds(cognitoUser);
  return accounts;
};

export const useCognitoUserValidAccountsLoading = () => {
  const cognitoUser = useAuthMachineCognitoUser();
  const { isLoading } = getCognitoUserAccountIds(cognitoUser);
  return isLoading;
};

const getLastTAccIdFromStorage = (storage: Storage) => {
  const storageLastTAccId = storage.getItem('last_T_ACC.ID');
  if (storageLastTAccId == null) {
    return null;
  }
  const parsedStorageLastTAccId = parseInt(storageLastTAccId, 10);
  if (Number.isNaN(parsedStorageLastTAccId)) {
    return null;
  }
  return parsedStorageLastTAccId;
};

export const selectedTAccIdLocationSearchAtom = atom((get) => {
  const parsedSearch = get(locationSearchParsedAtom);
  const {
    'T_ACC.ID': tAccId,
  } = parsedSearch;
  const parsedTAccId = parsePositiveNumberFromQs(tAccId);
  return parsedTAccId;
});

export const selectedTAccIdAtom = atom((get) => {
  const parsedUrlTAccId = get(selectedTAccIdLocationSearchAtom);
  if (parsedUrlTAccId !== null) {
    return parsedUrlTAccId;
  }
  const sessionStorage = getStorage('session');
  const sessionStorageLastTAccId = getLastTAccIdFromStorage(sessionStorage);
  if (sessionStorageLastTAccId !== null) {
    return sessionStorageLastTAccId;
  }
  const localStorage = getStorage('local');
  const localStorageLastTAccId = getLastTAccIdFromStorage(localStorage);
  if (localStorageLastTAccId !== null) {
    return localStorageLastTAccId;
  }
  return null;
});

export const SelectedAccountContextProvider = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  const localStorage = getStorage('local');
  const sessionStorage = getStorage('session');
  const accountIds = useCognitoUserValidAccounts();
  const tAccIdWithStorage = useAtomValue(selectedTAccIdAtom);
  const validTAccIdWithStorage = tAccIdWithStorage && accountIds.includes(tAccIdWithStorage) ? tAccIdWithStorage : null;
  const accountId = (!isNil(validTAccIdWithStorage)) ? validTAccIdWithStorage : getCognitoUserFirstAccountId(accountIds);
  const validTAcc = accountId && accountIds.includes(accountId) ? accountId : null;
  const connected = useIsConnected();
  useEffect(() => {
    if (validTAcc !== null && connected) {
      sessionStorage.setItem('last_T_ACC.ID', `${validTAcc}`);
      localStorage.setItem('last_T_ACC.ID', `${validTAcc}`);
    }
  }, [validTAcc]);
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const tAccIdState = useHookstate(valueState.tAccId);
  React.useEffect(() => {
    tAccIdState.set(validTAcc);
  }, [validTAcc, valueState]);
  return (
    <TAccIdContextProvider tAccId={`${validTAcc}`}>
      {children}
    </TAccIdContextProvider>
  );
};
