export const ONLINE_SECTIONS = [
  'details',
  'history',
  'orders',
  'timetable',
  'documents',
  'transfers',
  'rates',
];

export const DEFAULT_ONLINE_SECTION = 'details';
