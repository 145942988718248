import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../../atoms';
import { getValidOrdersSection } from './utils';

export const pathnameRawUrlAccountOrdersSectionAtom = atom((get) => {
  const subSection = get(pathnamePartFamilly(3));
  if (subSection == null) {
    return '';
  }
  return subSection;
});

export const pathnameUrlAccountOrdersSectionAtom = atom((get) => {
  const subSection = get(pathnameRawUrlAccountOrdersSectionAtom);
  return getValidOrdersSection(subSection);
});
