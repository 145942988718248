import * as React from 'react';
import { ActorApiTable, ActorApiTableProps } from '../../../../../../components';
import { useAccountOrdersList } from '../../hooks';
import { makeLoadingIds } from '../../../../shared/utils/makeLoadingIds';
import { AccountsOrder } from '../../type';

const loadingValues = makeLoadingIds(10);

export const AccountsOrdersTable = (props: React.PropsWithChildren<Pick<ActorApiTableProps, 'topModels' | 'direction' | 'wrapping' | 'multiBody' | 'secondaryDimensionList'> & { sortPredicate: (prev: AccountsOrder, next: AccountsOrder) => number }>) => {
  const {
    wrapping,
    multiBody,
    secondaryDimensionList,
    sortPredicate,
    topModels,
    children,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useAccountOrdersList({
    sortPredicate,
  });
  return (
    <ActorApiTable
      data={data}
      isLoading={isLoading}
      error={error}
      loadingValue={loadingValues}
      direction="vertical"
      wrapping={wrapping}
      multiBody={multiBody}
      secondaryDimensionList={secondaryDimensionList}
      topModels={topModels}
    >
      {children}
    </ActorApiTable>
  );
};
