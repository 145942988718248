export const getNewsSectionWithDefault = (section: string | undefined) => {
  if (section == null || section === '') {
    return '';
  }
  return section;
};

export const getNewsSectionType = (section: string) => {
  if (section === '' || section === 'search') {
    return section;
  }
  return 'article';
};
