/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { computedLineHeight } from '@goldwasserexchange/oui-style/src/utils';
import { State, useHookstate } from '@hookstate/core';

const style: ouiStyle.InStyleWithMediaQueries = {
  widthLines: 'full',
  lines: 0.5,
  outline: 'none',
  backgroundColor: ouiStyle.Constants.Colors.fade,
};

export const RangeInput = (props: {
  hookState: State<string>,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
  disabled?: boolean,
  disabledShowValue?: boolean,
  rangeMin: number,
  rangeMax: number,
  step?: number,
}) => {
  const {
    hookState,
    onChange,
    rangeMin,
    rangeMax,
    step,
    disabled = false,
    disabledShowValue = false,
  } = props;
  const valueState = useHookstate(hookState);
  const value = parseFloat(valueState.get());
  const percentage = ((value - rangeMin) / (rangeMax - rangeMin)) * 100;
  const css = React.useMemo(() => {
    const thumbCss = {
      appearance: 'none',
      width: !disabled ? `${1.5 * computedLineHeight}rem` : '0',
      height: !disabled ? `${1.5 * computedLineHeight}rem` : '0',
      borderRadius: '99999px',
      backgroundColor: !disabled ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.fade,
    };
    return ouiStyle.mediaQuery({
      ...ouiStyle.makeCss(style),
      appearance: 'none',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      '&::-webkit-slider-thumb': thumbCss,
      '&::-moz-range-thumb': thumbCss,
    });
  }, [
    disabled,
  ]);
  return (
    <input
      css={css}
      style={{
        background: !disabled || disabledShowValue
          ? `linear-gradient(90deg, ${ouiStyle.Constants.Colors.primaryShaded} ${percentage}%, ${ouiStyle.Constants.Colors.fade} ${percentage}%)`
          : undefined,
      }}
      disabled={disabled}
      type="range"
      value={value}
      onChange={onChange}
      min={rangeMin}
      max={rangeMax}
      step={step}
    />
  );
};
