import ouiBase from '@goldwasserexchange/oui';
import { I18n } from '@lingui/core';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useAccountOrderTransformApiByOpbFordId } from '../api';
import { AccountsOrder } from '../../type';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';

const transformStatus = (accountOrder: AccountsOrder | undefined) => {
  if (accountOrder == null) {
    return undefined;
  }
  const {
    'OPB_FORD.SWIFT_ACK': swiftAck,
    'OPB_FORD.CMT': comment,
    'OPB_FORD.PRICE_EXE': price,
    'OPB_FORD.MC_FREEZE': mcFreeze,
    'OPB_FORD_GE.SEND_DATE': sendDate,
  } = accountOrder;
  if (price) { // If there's a price always consider the order as executed
    return 'executed';
  }
  if (mcFreeze === 22) { // If the order was closed in Actor always consider it cancelled
    return 'cancelled';
  }
  if (sendDate) { // Order was sent to KBC
    if (swiftAck) { // Feedback received from KBC
      if (swiftAck.startsWith('MSH8855')) {
        return 'executed';
      }
      if (swiftAck.startsWith('MSH7997')) {
        return 'partially_executed';
      }
      if (swiftAck.startsWith('MSH7995') || swiftAck.startsWith('MSH8001')) {
        return 'cancelled';
      }
      if (swiftAck.startsWith('MSH7994')) {
        return 'cancel_rejected';
      }
      if (swiftAck.startsWith('MSH7992') || comment?.startsWith('CANCEL') || comment?.startsWith('SENT CANCEL')) {
        return 'cancel_requested';
      }
      if (swiftAck.startsWith('MSH7999') || swiftAck.startsWith('MSI0151')) {
        return 'pending';
      }
      return undefined; // Unknown status from KBC
    }
    // Feedback not yet received from KBC
    if (comment?.startsWith('CANCEL') || comment?.startsWith('SENT CANCEL')) {
      return 'cancel_requested';
    }
    if (comment?.startsWith('MSH') || comment?.startsWith('MSI') || comment?.startsWith('MSG')) {
      return 'error';
    }
    return 'sent';
  }
  // Order not yet sent to KBC
  if (comment?.startsWith('CANCEL')) {
    return 'cancel_requested';
  }
  return 'created';
};

export const useAccountOrderStatus = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: transformStatus,
  });
  return response;
};

const makeTransformStatusText = (i18n: I18n) => (accountOrder: AccountsOrder | undefined) => {
  if (accountOrder == null) {
    return '';
  }
  const type = transformStatus(accountOrder);
  switch (type) {
    case 'created':
      return i18n._(msg`Créé`);
    case 'sent':
      return i18n._(msg`Envoyé`);
    case 'pending':
      return i18n._(msg`En cours`);
    case 'cancelled':
      return i18n._(msg`Annulé`);
    case 'cancel_requested':
      return i18n._(msg`Annulation demandée`);
    case 'cancel_rejected':
      return i18n._(msg`Annulation rejetée`);
    case 'executed': {
      const {
        'OPB_FORD.PRICE_EXE': priceExe,
        'S_TYPE.MC_GROSS_OPB': mcGrossOpb,
      } = accountOrder;
      if (priceExe == null) {
        return i18n._(msg`Exécuté`);
      }
      const priceInPercent = (mcGrossOpb === 501 || mcGrossOpb === 505);
      const numberFormatter = useNumberFormatter({
        format: {
          style: priceInPercent ? 'percent' : 'decimal',
          minimumFractionDigits: 0,
        },
        divider: priceInPercent ? 100 : 1,
      });
      return `${i18n._(msg`Exécuté`)} (${numberFormatter(priceExe)})`;
    }
    case 'partially_executed':
      return i18n._(msg`Exécuté partiellement`);
    case 'error':
      return i18n._(msg`Erreur`);
    default:
      return i18n._(msg`Inconnu`);
  }
};

export const useAccountOrderStatusText = () => {
  const {
    i18n,
  } = useLingui();
  const transformFunction = useCallback(makeTransformStatusText(i18n), [i18n]);
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction,
  });
  return response;
};

const transformStatusToColor = (accountOrder: AccountsOrder | undefined) => {
  if (accountOrder == null) {
    return ouiStyle.Constants.Colors.primary;
  }
  const type = transformStatus(accountOrder);
  switch (type) {
    case 'pending':
      return ouiStyle.Constants.Colors.fadeDarker;
    case 'error':
    case 'cancel_rejected':
      return ouiStyle.Constants.Colors.danger;
    case 'cancel_requested':
      return ouiStyle.Constants.Colors.warning;
    case 'executed':
    case 'partially_executed':
      return ouiStyle.Constants.Colors.success;
    case 'created':
    case 'sent':
    case 'cancelled':
    default:
      return ouiStyle.Constants.Colors.primary;
  }
};

export const useAccountOrderOpbFordSwiftAckByTypeColor = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction: transformStatusToColor,
  });
  return response;
};
