import { ONLINE_SECTIONS } from './constants';

export const AccountsSectionRouteDefinition = {
  [ONLINE_SECTIONS[0]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/details/component'),
  },
  [ONLINE_SECTIONS[1]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/history/component'),
  },
  [ONLINE_SECTIONS[2]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/orders/component'),
  },
  [ONLINE_SECTIONS[3]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/timeTable/component'),
  },
  [ONLINE_SECTIONS[4]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/documents/component'),
  },
  [ONLINE_SECTIONS[5]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/transfers/component'),
  },
  [ONLINE_SECTIONS[6]]: {
    importFn: () => import('../../../../Components/Sections/online/sections/rates/component'),
  },
  'not-found': {
    importFn: () => import('../../../../Components/Sections/online/sections/notFound/component'),
  },
  'no-account': {
    importFn: () => import('../../../../Components/Sections/online/sections/noAccount/component'),
  },
  'no-actor': {
    importFn: () => import('../../../../Components/Sections/online/sections/noActor/component'),
  },
};
