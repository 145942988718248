/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import {
  useOnChange,
  useOnFocus,
  useOnBlur,
} from '../../../../../../../../../OUI/utils';
import { defaultRadioListInputListElementLabelContainerStyle } from '../../../../../../radio/components';
import {
  useSearchValueMethods,
  useInputRef,
  useFindSelectedFnInListData,
  useGetGetFn,
  useFirstSearchKey,
} from '../../../../../../../../../OUI/Inputs';
import { useMemo } from 'react';

const defaultDomSelectInputListSearchInputElementStyle: ouiStyle.InStyle = {
  ...defaultRadioListInputListElementLabelContainerStyle,
  paddingVertical: 'none',
  childPaddingBottom: 'simple',
  childPaddingTop: 'simple',
  fontSize: 'xs',
  paddingLeft: 'simple',
};

export function DefaultDomSelectInputListSearchInputElement(props: {
  placeholder: string,
  color?: ouiStyle.InStyleWithMediaQueries['color'],
}) {
  const {
    placeholder,
    color,
  } = props;
  const css = useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultDomSelectInputListSearchInputElementStyle,
    color: color ?? defaultDomSelectInputListSearchInputElementStyle.color,
  })), [color]);
  const inputRef = useInputRef();
  const onChange = useOnChange();
  const onFocus = useOnFocus();
  const onBlur = useOnBlur();
  const searchValueMethods = useSearchValueMethods();
  const value = searchValueMethods?.get();
  const isOpen = ouiBase.List.useSelectIsOpen();
  const findSelectedFn = useFindSelectedFnInListData();
  const getFn = useGetGetFn();
  const firstSearchKey = useFirstSearchKey();
  return (
    <input
      ref={inputRef}
      css={css}
      type="text"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={isOpen ? value : (getFn(findSelectedFn()[0], firstSearchKey) ?? '')}
      placeholder={placeholder}
    />
  );
}
