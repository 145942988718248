/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiBase from '@goldwasserexchange/oui';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';

export const defaultListInputListElementButtonPlaceHolderStyle: ouiStyle.InStyle = {
  display: ouiStyle.Constants.DisplayProp.flex,
  flexDirection: ouiStyle.Constants.InFlexDirectionProp.col,
  alignItems: ouiStyle.Constants.AlignItemsProp.center,
  justifyContent: ouiStyle.Constants.JustifyContentProp.center,
  fontSize: 's',
  flex: 'none',
  padding: 'simple',
  baselinePadding: true,
  iconContainer: true,
};

function useMakedefaultListInputListElementButtonPlaceHolderCss(props: {
  padding: ouiStyle.InStyleWithMediaQueries['padding'],
  paddingVertical: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
  paddingHorizontal: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
  paddingTop: ouiStyle.InStyleWithMediaQueries['paddingTop'],
  paddingBottom: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
  paddingLeft: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
  paddingRight: ouiStyle.InStyleWithMediaQueries['paddingRight'],
}) {
  const {
    padding = defaultListInputListElementButtonPlaceHolderStyle.padding,
    paddingVertical = padding,
    paddingHorizontal = padding,
    paddingTop = paddingVertical,
    paddingBottom = paddingVertical,
    paddingLeft = paddingHorizontal,
    paddingRight = paddingHorizontal,
  } = props;
  const lines = ouiBase.List.useListInputElementLines();
  const css = React.useMemo(() => ouiStyle.mediaQuery(ouiStyle.makeCss({
    ...defaultListInputListElementButtonPlaceHolderStyle,
    lines,
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  })), [
    lines,
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  ]);
  return css;
}

export function DefaultListInputListElementButtonPlaceHolder(
  props: React.PropsWithChildren<{
    padding?: ouiStyle.InStyleWithMediaQueries['padding'],
    paddingVertical?: ouiStyle.InStyleWithMediaQueries['paddingVertical'],
    paddingHorizontal?: ouiStyle.InStyleWithMediaQueries['paddingHorizontal'],
    paddingTop?: ouiStyle.InStyleWithMediaQueries['paddingTop'],
    paddingBottom?: ouiStyle.InStyleWithMediaQueries['paddingBottom'],
    paddingLeft?: ouiStyle.InStyleWithMediaQueries['paddingLeft'],
    paddingRight?: ouiStyle.InStyleWithMediaQueries['paddingRight'],
  }>,
) {
  const {
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    children,
  } = props;
  const css = useMakedefaultListInputListElementButtonPlaceHolderCss({
    padding,
    paddingVertical,
    paddingHorizontal,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  });
  return <div css={css}>{children}</div>;
}
