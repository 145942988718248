import {
  Update,
} from 'history';
import qs from 'query-string';
import { MessageDescriptor } from '@lingui/core';
import { HeadProps } from '../../type';
import { splitPathname } from '../../../utils';
import { NEWS_SECTIONS_TITLES } from './titles';
import { createHrefs } from '../../utils';
import { getTitleFromMessageDescriptor } from '../../utils/getTitle';
import { getNewsSectionType, getNewsSectionWithDefault } from '../../../router';

export const makeNewsSectionHeadProps = (currentUpdate: Update, currentHeadProps: HeadProps) => {
  const headProps = {
    ...currentHeadProps,
  };
  const {
    location,
  } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const {
    language,
  } = headProps;
  const newsSection = getNewsSectionType(getNewsSectionWithDefault(splittedPathname.at(2) ?? ''));
  if (newsSection !== '' && newsSection !== 'search') {
    headProps.title = undefined;
    headProps.href = undefined;
    headProps.frHref = undefined;
    headProps.nlHref = undefined;
    return headProps;
  }
  const titleMessageDescriptor = NEWS_SECTIONS_TITLES[newsSection] as MessageDescriptor;
  const title = getTitleFromMessageDescriptor(titleMessageDescriptor, language);
  if (newsSection === 'search') {
    const {
      search,
    } = location;
    const parsedSearch = qs.parse(search);
    const {
      search: searchTerm,
    } = parsedSearch;
    if (searchTerm == null || Array.isArray(searchTerm) || searchTerm === '') {
      headProps.title = title;
    } else {
      headProps.title = `${title} - ${searchTerm}`;
    }
  } else {
    headProps.title = title;
  }
  const {
    href,
    frHref,
    nlHref,
  } = createHrefs(currentUpdate);
  headProps.href = href;
  headProps.frHref = frHref;
  headProps.nlHref = nlHref;
  return headProps;
};
