/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import ouiStyle from '@goldwasserexchange/oui-style';

const style: ouiStyle.InStyleWithMediaQueries = {
  container: ouiStyle.Constants.Container.sm,
};

const css = ouiStyle.mediaQuery(ouiStyle.makeCss(style));

export const CommunicationMethodsExplanationContainer = (props: React.PropsWithChildren<{}>) => {
  const {
    children,
  } = props;
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
