import { atom } from 'jotai';
import { pathnamePartFamilly } from '../../../../atoms';

export const pathnameUrlBondDetailsParts = atom((get) => {
  const pathPart = get(pathnamePartFamilly(3));
  if (pathPart == null) {
    return null;
  }
  return pathPart.split('_');
});

export const pathnameUrlBondDetailsIssuerId = atom((get) => {
  const issuerIdString = get(pathnameUrlBondDetailsParts)
    ?.at(-2);
  if (issuerIdString == null) {
    return null;
  }
  const parsedIssuerId = parseInt(issuerIdString, 10);
  if (Number.isNaN(parsedIssuerId)) {
    return null;
  }
  return parsedIssuerId;
});

export const pathnameUrlBondDetailsIdAtom = atom((get) => {
  const idString = get(pathnameUrlBondDetailsParts)
    ?.at(-1);
  if (idString == null) {
    return null;
  }
  const parsedId = parseInt(idString, 10);
  if (Number.isNaN(parsedId)) {
    return null;
  }
  return parsedId;
});
