import * as React from 'react';
import { useAtomValue } from 'jotai';
import {
  BOND_SECTION_TITLES,
  ValidSections,
  createLanguagePathnames,
  getTitleFromMessageDescriptor,
  history,
  locationPathnameAtom,
  locationStateAtom,
  pathnameUrlBondDetailsIdAtom,
  pathnameUrlBondsSectionAtom,
  pathnameUrlLanguageAtom,
  pathnameUrlOblisSchoolLevelAtom,
  toHrefPaths,
} from '../../../history';
import { BondSAccIdFromUrlProvider, useBondsBondFullName, useBondsBondSlug } from '../../../actor/api/Services/bonds';
import { setHead } from '../utils';
import { useOblisSchoolArticleName } from '../../Sections/bonds/components/sections/oblisSchool/context';
import { useCmsArticle } from '../../cms';
import { createPath } from 'history';

const makeHrefs = (path: string) => {
  const {
    frPathname,
    nlPathname,
  } = createLanguagePathnames(path);
  const hrefs = toHrefPaths({
    path,
    frPath: frPathname,
    nlPath: nlPathname,
  });
  return hrefs;
};

const BondsSubsectionDetailsHeadInner = (props: {
  id: number | null,
}) => {
  const {
    id,
  } = props;
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const section = ValidSections.BONDS;
  const bondsSection = useAtomValue(pathnameUrlBondsSectionAtom);
  const {
    data,
  } = useBondsBondFullName();
  const {
    isLoading,
    data: slug,
  } = useBondsBondSlug();
  const detailedTitle = data ?? getTitleFromMessageDescriptor(BOND_SECTION_TITLES.details, language);
  const sourcePathname = useAtomValue(locationPathnameAtom);
  const state = useAtomValue(locationStateAtom);
  const pathname = `/${language}/${section}/${bondsSection}/${slug ?? id}`;
  if (sourcePathname !== pathname && !isLoading) {
    history.replace(createPath({ ...history.location, pathname }), state);
  }
  const {
    href,
    frHref,
    nlHref,
  } = makeHrefs(pathname);
  setHead({
    href,
    frHref,
    nlHref,
    language,
    title: detailedTitle,
    run: data != null,
  });
  return null;
};

const BondsSubsectionDetailsHead = () => {
  const id = useAtomValue(pathnameUrlBondDetailsIdAtom);
  return <BondsSubsectionDetailsHeadInner id={id} />;
};

const BondsSubsectionOblisSchoolHead = () => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const section = ValidSections.BONDS;
  const bondsSection = useAtomValue(pathnameUrlBondsSectionAtom);
  const oblisSchoolLevel = useAtomValue(pathnameUrlOblisSchoolLevelAtom);
  const oblisSchoolArticle = useOblisSchoolArticleName();
  const fullName = `${bondsSection}/${oblisSchoolLevel}/${oblisSchoolArticle ?? ''}`;
  const pathname = `/${language}/${section}/${fullName}`;
  const {
    data,
  } = useCmsArticle(fullName);
  const detailedTitle = data?.split('\n')[0].replace(/^# /g, '').trim() ?? getTitleFromMessageDescriptor(BOND_SECTION_TITLES['oblis-school'], language);
  const {
    href,
    frHref,
    nlHref,
  } = makeHrefs(pathname);
  setHead({
    href,
    frHref,
    nlHref,
    language,
    title: detailedTitle,
    run: data != null,
  });
  return null;
};

export const BondsSubsectionHead = () => {
  const bondsSection = useAtomValue(pathnameUrlBondsSectionAtom);
  switch (bondsSection) {
    case 'details':
      return (
        <BondSAccIdFromUrlProvider>
          <BondsSubsectionDetailsHead />
        </BondSAccIdFromUrlProvider>
      );
    case 'oblis-school':
      return <BondsSubsectionOblisSchoolHead />;
    default:
      return null;
  }
};
