import { getTopModelFromChildren, TopModel, TopModelMerge } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import ReactDOM from 'react-dom';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useIsCurrentModal, useSetCurrentModalValue } from './context/currentModal/hooks';
import { CloseButon } from './closeButton';
import { ModalContainer } from './container';
import { ModalIconContainer } from './icon/container';
import { useSetCanCloseCurrentModal } from './context/canCloseModal';
import { BackButton } from './backButton';
import { useScrollBlock } from '../useScrollBlock';

let modalRoot: Element | null = document.getElementById('modalPortal');

const defaultTopModels = {
  ModalCloseButton: (
    <TopModel name="ModalCloseButton">
      {CloseButon}
    </TopModel>
  ),
  ModalBackButton: (
    <TopModel name="ModalBackButton">
      {BackButton}
    </TopModel>
  ),
  ModalIconContainer: (
    <TopModel name="ModalIconContainer">
      {ModalIconContainer}
    </TopModel>
  ),
};

export const Modal = (props: React.PropsWithChildren<{
  modal: string,
  canClose?: boolean,
  goBackOnClick?: React.MouseEventHandler<HTMLButtonElement>,
  hasBack?: boolean,
  fullScreenUpMd?: boolean,
  textAlign?: ouiStyle.InStyle['textAlign'],
}>) => {
  const {
    modal,
    canClose = true,
    goBackOnClick,
    hasBack,
    fullScreenUpMd,
    textAlign,
    children: incomingChildren,
  } = props;
  const [children, childTopModels] = getTopModelFromChildren(incomingChildren);
  const isCurrentModal = useIsCurrentModal(modal);
  const closeModal = useSetCurrentModalValue(null);
  React.useEffect(() => closeModal, [closeModal]);
  const setCanClose = useSetCanCloseCurrentModal();
  React.useEffect(() => {
    if (isCurrentModal) {
      setCanClose(canClose);
    }
    return () => setCanClose(true);
  }, [setCanClose, canClose, isCurrentModal]);
  if (!modalRoot) {
    modalRoot = document.getElementById('modalPortal');
    if (!modalRoot) {
      throw new Error('there is no html element with the id "modalPortal" to portal the modal');
    }
  }
  const setScrollBlock = useScrollBlock();
  React.useEffect(() => {
    if (isCurrentModal) {
      setScrollBlock(true);
    } else {
      setScrollBlock(false);
    }
    return () => { setScrollBlock(false); };
  }, [isCurrentModal]);
  return isCurrentModal && modalRoot ? ReactDOM.createPortal(
    (
      <TopModelMerge
        topModels={defaultTopModels}
      >
        <TopModelMerge
          topModels={childTopModels}
        >
          <ModalContainer
            fullScreenUpMd={fullScreenUpMd}
            textAlign={textAlign}
            goBackOnClick={goBackOnClick}
            hasBack={hasBack}
          >
            {children}
          </ModalContainer>
        </TopModelMerge>
      </TopModelMerge>
    ), modalRoot,
  ) : null;
};
