import {
  Update,
} from 'history';
import { MessageDescriptor } from '@lingui/core';
import { HeadProps } from '../../type';
import { splitPathname } from '../../../utils';
import { BOND_SECTION_TITLES } from './titles';
import { createHrefs } from '../../utils';
import { getTitleFromMessageDescriptor } from '../../utils/getTitle';
import { getValidBondSection } from '../../../router';

export const makeBondsSectionHeadProps = (currentUpdate: Update, currentHeadProps: HeadProps) => {
  const headProps = {
    ...currentHeadProps,
  };
  const {
    location,
  } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const {
    language,
  } = headProps;
  const bondsSection = getValidBondSection(splittedPathname.at(2));
  if (bondsSection === 'details' || bondsSection === 'oblis-school') {
    headProps.title = undefined;
    headProps.href = undefined;
    headProps.frHref = undefined;
    headProps.nlHref = undefined;
    return headProps;
  }
  const titleMessageDescriptor = (BOND_SECTION_TITLES[bondsSection] ?? BOND_SECTION_TITLES['']) as MessageDescriptor;
  headProps.title = getTitleFromMessageDescriptor(titleMessageDescriptor, language);
  const {
    href,
    frHref,
    nlHref,
  } = createHrefs(currentUpdate);
  headProps.href = href;
  headProps.frHref = frHref;
  headProps.nlHref = nlHref;
  return headProps;
};
