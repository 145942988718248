import { useContext, useMemo } from 'react';
import { AccountsOrdersParam } from '../type';
import { accountsOrdersParamsContext } from '../context';
import { useTAccId } from '../../../shared';
import { useIsConnected } from '../../../../../../Components/Auth';
import { stringifyParams } from '../../../shared/utils/makeUrl';

export const useAccounOrdersDefaultParams = (params: Partial<AccountsOrdersParam>): Partial<AccountsOrdersParam> | null => {
  const {
    'T_ACC.ID': contextTAccId,
  } = useContext(accountsOrdersParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const isConnected = useIsConnected();
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  if (!tAccId || !isConnected) {
    return null;
  }
  return defaultParams;
};

export const useAccounOrdersStringifiedParams = () => {
  const contextParams = useAccounOrdersDefaultParams({});
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
