import { AccountsOrder } from '../../type';
import { useAccountOrderLinesTransformApi } from '../api';

const transformFunction = (data: AccountsOrder[] | undefined): string[] => (data ? data.map((d) => `${d['OPB_FORD.ID']}`) : []);

export const useAccountOrdersList = (props?: {
  sortPredicate?: (prev: AccountsOrder, next: AccountsOrder) => number,
}) => {
  const {
    sortPredicate,
  } = props ?? {};
  const result = useAccountOrderLinesTransformApi({
    transformFunction,
    sortPredicate,
  });
  return result;
};
