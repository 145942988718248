import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { getValidBondSection } from './utils';
import { bondsDetailsRedirect } from './details';
import { bondsOblisSchoolRedirect } from './oblisSchool';

export const bondsRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  let splittedPathname = splitPathname(pathname);
  let bondsSection = splittedPathname.at(2);
  if (bondsSection === 'list') {
    splittedPathname = [...splittedPathname.slice(0, 2), '', ...splittedPathname.slice(3)];
    update.location.pathname = `/${splittedPathname.join('/')}`;
    bondsSection = '';
  }
  const validBondsSection = getValidBondSection(bondsSection);
  if (validBondsSection !== 'not-found' && validBondsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validBondsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  switch (validBondsSection) {
    case 'details':
      return bondsDetailsRedirect(update);
    case 'oblis-school':
      return bondsOblisSchoolRedirect(update);
    default:
      return update;
  }
};
