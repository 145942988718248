import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId } from '../api';

export const useAccountOrderSAccId = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'S_ACC.ID',
  });
  return response;
};
