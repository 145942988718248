/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import { useTopModelExistByName } from '@goldwasserexchange/react-topmodel';
import * as React from 'react';
import { useHookstate } from '@hookstate/core';
import { Untracked } from '@hookstate/untracked';
import { useAnimationCollapseSpringValue } from '../AppBar/animation';
import { useBannerHeight } from '../banner/context';
import { useModalZIndex } from './context/zIndex';

const downMdTop = `${ouiStyle.Constants.space * 6}rem`;

const widthLines = ['full', undefined, undefined, 25, undefined, undefined] as const;

const style: ouiStyle.InStyleWithMediaQueries = {
  position: 'fixed',
  left: '50%',
  transform: ['translateX(-50%)', undefined, undefined, 'translateX(-50%) translateY(-50%)', undefined, undefined],
  backgroundColor: ouiStyle.Constants.Colors.inverted,
  boxShadow: '0 0 10px 3px rgba(0, 0, 0, 0.1)',
};

const lines = ['full', undefined, undefined, 'auto', undefined, undefined] as const;

const hasIconMaxHeight = ['100vh', '100vh', '100vh', `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`, `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`, `calc(100vh - ${21 * ouiStyle.Constants.space}rem)`];

export const ModalWrapper = (props: React.PropsWithChildren<{
  fullScreenUpMd?: boolean,
}>) => {
  const {
    fullScreenUpMd = false,
    children,
  } = props;
  const zIndex = useModalZIndex();
  const hasIcon = useTopModelExistByName('ModalIconContent');
  const collapse = useAnimationCollapseSpringValue();
  const bannerHeight = useBannerHeight();
  const isUncollapsed = useHookstate(collapse.get() === 0);
  isUncollapsed.attach(Untracked);
  React.useEffect(() => {
    const interval = setInterval(() => {
      const uncollapsed = collapse.get() === 0;
      if (Untracked(isUncollapsed).get() !== uncollapsed) {
        isUncollapsed.set(uncollapsed);
      }
    }, 50);
    return () => { clearInterval(interval); };
  });
  const css = React.useMemo(
    () => {
      const topBase = isUncollapsed.get() ? `calc(${downMdTop} + ${bannerHeight}px)` : 0;
      const minus = fullScreenUpMd === false ? [`${downMdTop} - ${bannerHeight}px`, undefined, undefined, '0', undefined, undefined] : `${downMdTop} - ${bannerHeight}px`;
      return ouiStyle.mediaQuery({
        ...ouiStyle.makeCss({
          ...style,
          widthLines: fullScreenUpMd === false ? widthLines : widthLines[0],
          lines: fullScreenUpMd === false ? lines : lines[0],
          zIndex,
          top: fullScreenUpMd === false
            ? [topBase, undefined, undefined, '50%', undefined, undefined]
            : topBase,
          transform: fullScreenUpMd === false
            ? ['translateX(-50%)', undefined, undefined, 'translateX(-50%) translateY(-50%)', undefined, undefined]
            : 'translateX(-50%)',
          minus: isUncollapsed.get() ? minus : '0',
        } as ouiStyle.InStyleWithMediaQueries),
        maxHeight: hasIcon ? hasIconMaxHeight : '100vh',
      });
    },
    [
      zIndex,
      hasIcon,
      collapse,
      bannerHeight,
      isUncollapsed.get(),
      fullScreenUpMd,
    ],
  );
  return (
    <div
      css={css}
    >
      {children}
    </div>
  );
};
