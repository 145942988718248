import * as React from 'react';
import { useAccountOrderOpbFordDQt } from '../../hooks';
import { ActorApiNumberDisplay } from '../../../../../../components/display/number/component';

export const AccountOrderOpbFordDQt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountOrderOpbFordDQt();
  return (
    <ActorApiNumberDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      format={{
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
    />
  );
};
