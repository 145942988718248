import * as React from 'react';
import { useAccountOrderOpbFordCreateAt } from '../../hooks';
import { ActorApiDateDisplay } from '../../../../../../components/display/date';

export const AccountOrderOpbFordCreateAt = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountOrderOpbFordCreateAt();
  if (!isLoading && data == null) {
    return <>/</>;
  }
  return (
    <ActorApiDateDisplay
      data={data}
      isLoading={isLoading}
      error={error}
      formatMq={false}
    />
  );
};
