import {
  dataStructure,
  UncastedDataStructure,
  GesEvalType,
  TAddType,
  TNatureLrp,
  TProcType,
  AccountType,
} from '@goldwasserexchange/oblis-frontend-utils';
import { DEFAULT_DURABILITY_INITIAL_VALUES } from '../Sections/onboarding/form/questionnaires/DurabilityQuestionnaire';
import {
  DEFAULT_FINANCIAL_SITUATION_QUESTIONNAIRE_INITIAL_VALUES,
} from '../Sections/onboarding/form/questionnaires/FinancialSituationQuestionnaire';
import {
  DEFAULT_INVESTMENT_GOALS_QUESTIONNAIRE_INITIAL_VALUES,
} from '../Sections/onboarding/form/questionnaires/InvestmentGoalsQuestionnaire';
import {
  initialTAdd,
} from '../tAdd';

export const makeInitialValues = (): UncastedDataStructure => ({
  onboarding: {
    version: 5,
    onboardingId: undefined,
    save: false,
    serviceAccessibility: {
      [AccountType.RTO]: true,
      [AccountType.CONSEIL]: true,
      [AccountType.GESTION]: true,
    },
    isRepresentativeExistingTitular: '',
    legalRepresentative: '',
    users: [{
      ...initialTAdd(false),
      T_ADD_type: TAddType.TITULAR,
      current: true,
      ID_T_NATURE_LRP: TNatureLrp.TITULAIRE,
      ID_T_PROC_TYPE: `${TProcType.GENERAL}`,
      isTRelMail: true,
      ID_T_ADD_ADDRESS_KEY: 'new',
    }],
    products: dataStructure.financialProductsExperience.initialValues(),
    moralPerson: {
      ...initialTAdd(true),
      T_ADD_type: TAddType.MORAL,
      ID_T_ADD_ADDRESS_KEY: 'new',
    },
    communicationType: {
      postal: true,
      mobilePhone: true,
      email: true,
      landLinePhone: true,
    },
    orientation: {
      decisionType: '',
      evaluationFrequency: '',
      marketNewsFrequency: '',
      threeYearInvestmentPlan: '',
      submitted: undefined,
    },
    selectedService: undefined,
    consent: {
      contractualTerms: undefined,
      generalTermsAndConditions: undefined,
      pricing: undefined,
      warningSkippedServiceRecommendation: undefined,
      warningDidNotFollowServiceRecommendation: undefined,
      withdrawalRight: undefined,
      monitorInvestments: undefined,
      globalPlusTechnicalSheet: undefined,
    },
    ID_RISK_PR_RISK: '',
    knowledgeQuestionnaire: dataStructure.financialProductsKnowledge.initialValues.makeAllQuestionnaireInitialValues(),
    financialSituation: DEFAULT_FINANCIAL_SITUATION_QUESTIONNAIRE_INITIAL_VALUES,
    investmentGoals: DEFAULT_INVESTMENT_GOALS_QUESTIONNAIRE_INITIAL_VALUES,
    durability: DEFAULT_DURABILITY_INITIAL_VALUES,
    personType: undefined,
    T_ACC: {
      ID_GES_EVAL_PER: '',
      idGesEvalType: `${GesEvalType.ONLINE}`,
    },
    fiscality: {
      'YN_PAY_PREC ': '',
    },
    isPrint: false,
    howDidYouHearAboutUs: {
      internetSearch: false,
      socialNetworks: false,
      companyPublication: false,
      press: false,
      wordOfMouth: false,
      other: false,
    },
    onboardingSubmitted: false,
    openPremiumAccount: '0',
    submittedDate: new Date().toISOString(),
    subscribeToNewsletter: false,
  },
  auth: {
    username: '',
    birthdate: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordRepeat: '',
    mfaCode: '',
    rememberDevice: '1',
    newPassword: '',
    newPasswordRepeat: '',
    changedPassword: '',
    changedPasswordRepeat: '',
    recaptcha: '',
    account: '',
    bucketName: null,
    identityId: '',
    connected: false,
    onboardingConnected: false,
    singleTableName: null,
  },
  order: {
    direction: 'BUY',
    productId: '',
    quantity: '',
    minimumQuantity: undefined,
    maximumQuantity: undefined,
    orderType: 'LIMIT',
    validity: 'DAY',
    accountId: '',
    gesEstimDId: '',
    price: '',
    marketPrice: '',
    currency: '',
    previous: '',
    confirmation: '0',
  },
  transfer: {
    communication: '',
    currency: '',
    amount: '',
    iban: '',
    maxAmount: '',
    amounts: [],
  },
  submitType: '',
  tAccId: '',
  tAddId: '',
  otp: '',
});
