import { Update } from 'history';
import { splitPathname } from '../../utils';
import { sectionsRoutesDefinition } from './router';
import { accountsRouting } from './accounts';
import { newsRouting } from './news';
import { bondsRouting } from './bonds';
import { onboardingsRouting } from './onboarding';
import { getValidSection } from './utils';

export const sectionsRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const section = splittedPathname.at(1);
  const validSection = getValidSection(section);
  const {
    importFn,
  } = sectionsRoutesDefinition[validSection];
  if (importFn != null && typeof importFn === 'function') {
    importFn();
  }
  switch (validSection) {
    case 'accounts':
      accountsRouting(currentUpdate);
      break;
    case 'news':
      newsRouting(currentUpdate);
      break;
    case 'bonds':
      bondsRouting(currentUpdate);
      break;
    case 'onboarding':
      onboardingsRouting(currentUpdate);
      break;
    default:
      break;
  }
};
