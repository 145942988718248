export type RateProductType = 'stocks' | 'bonds' | 'capitalisationFunds' | 'distibutionFunds' | 'trackers';

export const RATE_PRODUCT_TYPES: RateProductType[] = [
  'stocks',
  'bonds',
  'capitalisationFunds',
  'distibutionFunds',
  'trackers',
];

export const DEFAULT_RATE_PRODUCT_TYPE = RATE_PRODUCT_TYPES[0];
