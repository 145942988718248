import * as React from 'react';
import { useAtomValue } from 'jotai';
import {
  NOT_FOUND, ValidSections, sectionsRoutesDefinition, pathnameUrlSectionAtom,
} from '../../history';
import { Suspended } from '../Suspended';
import { ErrorBoundary } from '../ErrorBoundary';

const sectionsContentMap = {
  [ValidSections.HOME]: React.lazy(sectionsRoutesDefinition[ValidSections.HOME].importFn),
  [ValidSections.ONLINE]: React.lazy(sectionsRoutesDefinition[ValidSections.ONLINE].importFn),
  [ValidSections.NEWS]: React.lazy(sectionsRoutesDefinition[ValidSections.NEWS].importFn),
  [ValidSections.BONDS]: React.lazy(sectionsRoutesDefinition[ValidSections.BONDS].importFn),
  [ValidSections.CONTACT]: React.lazy(sectionsRoutesDefinition[ValidSections.CONTACT].importFn),
  [ValidSections.ONBOARDING]: React.lazy(sectionsRoutesDefinition[ValidSections.ONBOARDING].importFn),
  [ValidSections.LEGAL]: React.lazy(sectionsRoutesDefinition[ValidSections.LEGAL].importFn),
  [ValidSections.GLOBAL_PLUS_ACCOUNT]: React.lazy(sectionsRoutesDefinition[ValidSections.GLOBAL_PLUS_ACCOUNT].importFn),
  [ValidSections.GOLD_ACCOUNT]: React.lazy(sectionsRoutesDefinition[ValidSections.GOLD_ACCOUNT].importFn),
  [ValidSections.PREMIUM_ACCOUNT]: React.lazy(sectionsRoutesDefinition[ValidSections.PREMIUM_ACCOUNT].importFn),
  [ValidSections.SECURITY]: React.lazy(sectionsRoutesDefinition[ValidSections.SECURITY].importFn),
  [ValidSections.SIGN_UP]: React.lazy(sectionsRoutesDefinition[ValidSections.SIGN_UP].importFn),
  [ValidSections.UPLOAD]: React.lazy(sectionsRoutesDefinition[ValidSections.UPLOAD].importFn),
  [ValidSections.ORDER]: React.lazy(sectionsRoutesDefinition[ValidSections.ORDER].importFn),
  [NOT_FOUND]: React.lazy(sectionsRoutesDefinition[NOT_FOUND].importFn),
};

export const SectionContent = () => {
  const section = useAtomValue(pathnameUrlSectionAtom);
  const Content = sectionsContentMap[section];
  return (
    <ErrorBoundary key={section}>
      <Suspended paddingTop={section === ValidSections.HOME ? 4 : 'none'}>
        <Content />
      </Suspended>
    </ErrorBoundary>
  );
};
