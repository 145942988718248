import ouiBase from '@goldwasserexchange/oui';
import { useAccountPositionFieldApiByGesEstimD } from '../api';

export const useAccountPositionsSAccYnDefaulting = () => {
  const gesEstimDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const result = useAccountPositionFieldApiByGesEstimD({
    gesEstimDId,
    field: 'S_ACC.YN_DEFAULTING',
  });
  return result;
};
