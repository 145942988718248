import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderTransformApiByOpbFordId } from '../api';
import { makeBondSlugTransformFn } from '../../../../bonds/shared/makeBondSlug';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useAccountOrderSlug = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const transformFunction = makeBondSlugTransformFn({
    intRateFormatter,
    locale,
  });
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction,
  });
  return response;
};
