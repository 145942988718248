import * as React from 'react';
import { CheckboxListInput } from '../../../../../../../OUI-dom/Inputs';
import { UncastedDataStructure, UncastedOnboarding } from '@goldwasserexchange/oblis-frontend-utils';
import ouiBase from '@goldwasserexchange/oui';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import { Trans } from '@lingui/macro';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const subscribeToNewsletter: keyof Pick<UncastedOnboarding, 'subscribeToNewsletter'> = 'subscribeToNewsletter';

const list = ['newsletter'];

const checkboxPotentialFieldPaths = list.map(() => `${onboardingPath}.${subscribeToNewsletter}`);

const elementFieldPathHook = () => `${onboardingPath}.${subscribeToNewsletter}`;

const ElementLabel = () => (
  <Trans>Je souhaite m'inscrire à la newsletter</Trans>
);

export const NewsletterInputSection = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <CheckboxListInput
      fieldPath={valueState.onboarding.subscribeToNewsletter.path.join('.')}
      list={list}
      elementFieldPathHook={elementFieldPathHook}
      checkboxPotentialFieldPaths={checkboxPotentialFieldPaths}
      validateFieldPath
    >
      <TopModel name="CheckboxListInputLabelContent">
        <Trans>
          Newsletter (actualités et promotions)
        </Trans>
      </TopModel>
      <TopModel name="CheckboxListInputListElementLabelContent">
        {ElementLabel}
      </TopModel>
    </CheckboxListInput>
  );
};
