import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import ouiBase from '@goldwasserexchange/oui';
import { useCallback } from 'react';
import { useAccountOrderFieldTransfomApiByOpbFordId } from '../api';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';
import { getIdkZLang } from '../../../../../../../Components/LmlText';

export const useLmlGet = (language: string) => {
  const idk = getIdkZLang(language);
  const fn = useCallback((lmlString: string | undefined): string | undefined => (
    lmlString != null
      ? lmlGet(idk, lmlString)
      : undefined
  ), [idk]);
  return fn;
};

export const useAccountOrderPOpbLmlDescription = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const locale = useLinguiLocale();
  const transformFunction = useLmlGet(locale);
  const response = useAccountOrderFieldTransfomApiByOpbFordId({
    opbFordDId,
    field: 'P_OPB.LML_DESCRIPTION',
    transformFunction,
  });
  return response;
};
