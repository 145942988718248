import * as React from 'react';
import { useAtomValue } from 'jotai';
import { Locale } from '@goldwasserexchange/i18n';
import {
  JsonLd,
  NEWS_SECTIONS_TITLES,
  OptionalOpenGraphProps,
  ValidSections,
  getTitleFromMessageDescriptor,
  makeHref,
  pathnameUrlLanguageAtom,
  pathnameUrlNewsType,
} from '../../../history';
import { useSanityNewsByUrlId } from '../../../sanity';
import { sanityImageBuilder } from '../../../sanity/client';
import { setHead } from '../utils';

const NewsArticleSubSectionHead = () => {
  const language = useAtomValue(pathnameUrlLanguageAtom);
  const {
    data,
  } = useSanityNewsByUrlId();
  const slug = data?.slug;
  const url = slug != null
    ? `/${language}/${ValidSections.NEWS}/${slug}`
    : '';
  const title = data?.title ?? getTitleFromMessageDescriptor(NEWS_SECTIONS_TITLES[''], language);
  const href = makeHref(url);
  const frSlug = data?.translations?.find((translation) => translation.language === Locale.FR)?.slug;
  const frHref = frSlug != null
    ? makeHref(`/${Locale.FR}/${ValidSections.NEWS}/${frSlug}`)
    : undefined;
  const nlSlug = data?.translations?.find((translation) => translation.language === Locale.NL)?.slug;
  const nlHref = nlSlug != null
    ? makeHref(`/${Locale.NL}/${ValidSections.NEWS}/${nlSlug}`)
    : undefined;
  const imageBase = data != null
    ? sanityImageBuilder
      .image(data.image)
      .fit('max')
      .auto('format')
      .dpr(2)
    : null;
  // image sizes were chosen to be easily computable and round and respect the 50k pixel minimum advised in https://developers.google.com/search/docs/appearance/structured-data/article
  const imageSquare = imageBase?.size(250, 250)?.url();
  const imageFourThird = imageBase?.size(440, 330)?.url();
  const image16By9 = imageBase?.size(464, 261)?.url();
  const jsonLd: JsonLd | undefined = data != null
  && imageSquare != null
  && imageFourThird != null
  && image16By9 != null
    ? {
      type: 'NewsArticle',
      headline: data.title,
      datePublished: data.publishDate,
      // eslint-disable-next-line no-underscore-dangle
      dateModified: data._updatedAt,
      image: [
        imageSquare,
        imageFourThird,
        image16By9,
      ],
      author: [{
        '@type': 'Organization',
        name: 'Goldwasser Exchange',
        url: 'https://www.goldwasserexchange.be',
      }],
    }
    : undefined;
  const openGraphImage = imageBase?.size(1200, 630)?.url();
  const openGraph: OptionalOpenGraphProps | undefined = data != null && frHref != null && openGraphImage != null
    ? {
      type: 'article',
      description: data.intro,
      image: openGraphImage,
    }
    : undefined;
  setHead({
    href,
    frHref,
    nlHref,
    language,
    jsonLd,
    openGraph,
    title,
    run: slug != null,
  });
  return null;
};

export const NewsSubSectionHead = () => {
  const newsSubSectionHeadType = useAtomValue(pathnameUrlNewsType);
  if (newsSubSectionHeadType !== '' && newsSubSectionHeadType !== 'search') {
    return <NewsArticleSubSectionHead />;
  }
  return null;
};
