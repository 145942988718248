export const query = /* groq */`
*[
  _type == "banner"
  && to.utc > now()
  && (
    !defined(from)
    || from.utc < now()
  )
  && defined(content[][_key == $locale][0])
] 
| order(to.utc asc)
{
  "from": from.utc,
  "to": to.utc,
  "content": content[][_key == $locale][0],
  "color": color.hex,
  "backgroundColor": backgroundColor.hex,
}[0]
` as const;
