import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { getValidOnboardingSection } from './utils';

export const onboardingRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const onboardingSection = splittedPathname.at(2);
  const validBondsSection = getValidOnboardingSection(onboardingSection);
  if (validBondsSection !== 'not-found' && validBondsSection !== splittedPathname.at(2)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 2), validBondsSection, ...splittedPathname.slice(3)].join('/')}`;
  }
  return update;
};
