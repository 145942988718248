import { Update } from 'history';
import { splitPathname } from '../../../../utils';

export const bondsOblisSchoolRedirect = (currentUpdate: Update) => {
  const update = {
    action: currentUpdate.action,
    location: {
      ...currentUpdate.location,
    },
  };
  const { location } = update;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  let level = splittedPathname.at(3);
  if (level == null) {
    level = 'beginner';
  }
  if (level !== splittedPathname.at(3)) {
    update.location.pathname = `/${[...splittedPathname.slice(0, 3), level, ...splittedPathname.slice(4)].join('/')}`;
  }
  return update;
};
