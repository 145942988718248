import useSWR, { KeyedMutator } from 'swr';
import { makeApiUrl } from '../../../../../../aws';
import { getServiceUrl } from '../../../../shared';
import { useFetchWithAuthWithSend } from '../../../shared/utils/fetchWithAuth';
import { Accounts } from '../../enum';
import { useAccounOrdersPendingStringifiedParams } from './defaultParams';
import { AccountsOrderPending } from '../type';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useMemo } from 'react';

export const useMakeAccountsPendingOrdersUrl = () => {
  const stringifiedParams = useAccounOrdersPendingStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Accounts.PendingOrders)}${stringifiedParams}` : null;
  return url;
};

const useAccountsOrdersPendingFetcher = (run = true) => {
  const url = useMakeAccountsPendingOrdersUrl();
  const fetcher = useFetchWithAuthWithSend();
  const result = useSWR<AccountsOrderPending[], any>(
    run === true ? url : null,
    fetcher,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  return result;
};

type BaseUseAccountOrdersApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<AccountsOrderPending[]>,
  isValidating: boolean,
}

type UseAccountOrdersPendingLinesApiResponse = {
  data: undefined | AccountsOrderPending[],
} & BaseUseAccountOrdersApiResponse;

export type OrderPendingFilterPredicate = (accountOrder: AccountsOrderPending) => boolean;

type SortPredicate = (prevAccountOrder: AccountsOrderPending, nextAccountOrder: AccountsOrderPending) => number;

type UseAccountOrdersApiProps = {
  filterPredicate?: OrderPendingFilterPredicate,
  sortPredicate?: SortPredicate,
};

export const useAccountOrdersPendingApi = (props: UseAccountOrdersApiProps): UseAccountOrdersPendingLinesApiResponse => {
  const {
    filterPredicate,
    sortPredicate,
  } = props;
  const result = useAccountsOrdersPendingFetcher();
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    let { data } = result;
    if (data) {
      if (sortPredicate) {
        data = data.sort(sortPredicate);
      }
      if (filterPredicate) {
        data = data.filter((orderLine) => filterPredicate(orderLine));
      }
    }
    return ({
      ...result,
      data,
      isLoading,
    });
  }, [result, filterPredicate, sortPredicate]);
  return resultWithLoading;
};
