import { Update } from 'history';
import { splitPathname } from '../../../utils';
import { newsRoutesDefinition } from './router';
import { getNewsSectionType, getNewsSectionWithDefault } from './utils';

export const newsRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const newsSection = splittedPathname.at(2);
  const validNewsSection = getNewsSectionWithDefault(newsSection);
  const newsSectionType = getNewsSectionType(validNewsSection);
  const {
    importFn,
  } = newsRoutesDefinition[newsSectionType];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
};
