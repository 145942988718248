export type BondsSection =
  | ''
  | 'details'
  | 'oblis-school'
  | 'favorites'

export const BONDS_SECTIONS: BondsSection[] = [
  '',
  'details',
  'oblis-school',
  'favorites',
];
