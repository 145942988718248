import * as React from 'react';
import { useAccountOrderTypeAndPriceText } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const AccountOrderTypeAndPriceText = () => {
  const {
    data,
    isLoading,
    error,
  } = useAccountOrderTypeAndPriceText();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
