import { Update } from 'history';
import { languageRedirect } from './language';
import { sectionsRedirect } from './sections';

export const redirect = (currentUpdate: Update) => {
  let update = currentUpdate;
  update = languageRedirect(update);
  update = sectionsRedirect(update);
  return {
    ...update,
    location: {
      ...update.location,
      pathname: update.location.pathname.replace(/\/$/g, ''),
    },
  };
};
