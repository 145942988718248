/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import ouiStyle from '@goldwasserexchange/oui-style';
import * as React from 'react';
export var TableRowContainer = function (props) {
    var display = props.display, widthLines = props.widthLines, children = props.children;
    var css = React.useMemo(function () { return ouiStyle.makeCss({
        display: display,
        widthLines: widthLines,
    }); }, [
        display,
        widthLines,
    ]);
    return (jsx("tr", { css: ouiStyle.mediaQuery(css) }, children));
};
