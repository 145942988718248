import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { object } from 'yup';
import { isEmpty } from 'ramda';
import {
  makeName,
  dataStructure,
  UncastedDataStructure,
  CPhone,
  UncastedOnboarding,
} from '@goldwasserexchange/oblis-frontend-utils';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import { FieldValueSnippet } from '../../../Form/utils';
import { UserPostalAddress } from '../../../tAdd/PostalAddress';
import { CheckboxListInput } from '../../../../OUI-dom/Inputs';
import { GetComponentKeyByElementPrimaryKey } from '../../../Sections/onboarding/form/utils/ElementLabel';
import { booleanValidator, makeAtLeastOneTrue } from '../../../../OUI/Inputs';
import { CommunicationMethodsExplanation } from './components';

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const communicationType: keyof Pick<UncastedOnboarding, 'communicationType'> = 'communicationType';

const postalKey: keyof Pick<UncastedOnboarding['communicationType'], 'postal'> = 'postal';
export const FULL_COMMUNICATION_METHOD_POSTAL_KEY = makeName(onboardingPath, communicationType, postalKey);
export const communicationMethodPostalValidator = booleanValidator;

const mobilePhoneKey: keyof Pick<UncastedOnboarding['communicationType'], 'mobilePhone'> = 'mobilePhone';
export const FULL_COMMUNICATION_METHOD_PHONE_KEY = makeName(onboardingPath, communicationType, mobilePhoneKey);
export const communicationMethodMobilePhoneValidator = booleanValidator;

const emailKey: keyof Pick<UncastedOnboarding['communicationType'], 'email'> = 'email';
export const FULL_COMMUNICATION_METHOD_EMAIL_KEY = makeName(onboardingPath, communicationType, emailKey);
export const communicationMethodEmailValidator = booleanValidator;

const landLinePhoneKey: keyof Pick<UncastedOnboarding['communicationType'], 'landLinePhone'> = 'landLinePhone';
export const FULL_COMMUNICATION_METHOD_LANDLINE_PHONE_KEY = makeName(onboardingPath, communicationType, landLinePhoneKey);
export const communicationMethodLandLinePhoneValidator = booleanValidator;

const communicationMethodsValidatorShape = {
  postal: communicationMethodPostalValidator,
  mobilePhone: communicationMethodMobilePhoneValidator,
  email: communicationMethodEmailValidator,
  landLinePhone: communicationMethodLandLinePhoneValidator,
};

export const communicationMethodsValidator = makeAtLeastOneTrue(object().shape(communicationMethodsValidatorShape));

const tAccCommunicationMethodsLabel = <Trans>Le soussigné déclare faire usage des méthodes de contact suivantes pour la conduite de ses affaires :</Trans>;

const label = (
  <TopModel name="CheckboxListInputLabelContent">
    {tAccCommunicationMethodsLabel}
  </TopModel>
);

const CurrentUserPostalAddress = () => {
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  return (
    <UserPostalAddress userKey={currentUserIndex} withoutName />
  );
};

const PhoneFieldValueSnippet = (props: { shortcut: dataStructure.T_ADD.fields.PHONE.fields.ID_C_PHONE.list.listType }) => {
  const { shortcut } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  const currentUserState = valueState.onboarding.users[currentUserIndex];
  const phoneState = useHookstate(currentUserState.PHONE);
  const mobilePhoneFieldName = dataStructure.T_ADD.fields.PHONE.fields.hooks.getPhoneIndexByCPhoneShortCut(shortcut, phoneState.get());
  return (
    <FieldValueSnippet fieldState={valueState.onboarding.users[currentUserIndex].PHONE[mobilePhoneFieldName].PHONE} />
  );
};

const tAccCommunicationMethodsData: Record<number, {
  ID: number,
  name: string,
  value: JSX.Element,
}> = {
  0: {
    ID: 0,
    name: FULL_COMMUNICATION_METHOD_POSTAL_KEY,
    value: (
      <>
        <Trans>par courrier à l'adresse suivante :</Trans>
        <CurrentUserPostalAddress />
      </>
    ),
  },
  1: {
    ID: 1,
    name: FULL_COMMUNICATION_METHOD_PHONE_KEY,
    value: (
      <>
        <Trans>par téléphone mobile au numéro suivant :</Trans>
        {' '}
        <PhoneFieldValueSnippet shortcut={CPhone.GSM_PRINCIPAL} />
      </>
    ),
  },
  2: {
    ID: 2,
    name: FULL_COMMUNICATION_METHOD_LANDLINE_PHONE_KEY,
    value: (
      <>
        <Trans>par téléphone fixe au numéro suivant :</Trans>
        {' '}
        <PhoneFieldValueSnippet shortcut={CPhone.TEL_PRINCIPAL} />
      </>
    ),
  },
  3: {
    ID: 3,
    name: FULL_COMMUNICATION_METHOD_EMAIL_KEY,
    value: (
      <>
        <Trans>par email à l'adresse suivante :</Trans>
        {' '}
        <PhoneFieldValueSnippet shortcut={CPhone.EMAIL_PRINCIPAL} />
      </>
    ),
  },
};

const tAccCommunicationMethodsList = Object.keys(tAccCommunicationMethodsData);

const useTAccCommunicationMethodsFieldPathHook = () => {
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  return tAccCommunicationMethodsData[primaryKey].name;
};

const useTaccCommunicationMethodsGetFn = () => {
  const getFn = useCallback((source: string | { ID: string }, path): string | undefined => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    const element = tAccCommunicationMethodsData[parseInt(primaryKey, 10)] ?? {};
    const rawValue = element[path] ?? undefined;
    return rawValue;
  }, []);
  return getFn;
};

const useTAccCommunicationMethodsFilterFn = (list) => {
  const currentUserIndex = dataStructure.T_ADD.fields.CURRENT.hooks.useCurrentUserIndex();
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const currentUserPhoneState = useHookstate(valueState.onboarding.users[currentUserIndex].PHONE);
  const landLinePhoneIndex = dataStructure.T_ADD.fields.PHONE.fields.hooks.getPhoneIndexByCPhoneShortCut(
    CPhone.TEL_PRINCIPAL,
    currentUserPhoneState.get(),
  );
  const landLinePhone = useHookstate(currentUserPhoneState[landLinePhoneIndex].PHONE);
  const hasLandLinePhone = !isEmpty(landLinePhone.get());
  return useMemo(() => list.filter((id) => id !== '2'), [hasLandLinePhone, list]);
};

const tAccIdGesEvalTypeElementLabel = (
  <TopModel name="CheckboxListInputListElementLabelContent" data={tAccCommunicationMethodsData} valueKey="value">
    {GetComponentKeyByElementPrimaryKey}
  </TopModel>
);

const potentialFieldPaths = Object.values(tAccCommunicationMethodsData).map((data) => data.name);

export const TAccCommunicationMethodsInput = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  return (
    <>
      <CommunicationMethodsExplanation />
      <CheckboxListInput
        fieldPath={valueState.onboarding.communicationType.path.join('.')}
        list={tAccCommunicationMethodsList}
        elementFieldPathHook={useTAccCommunicationMethodsFieldPathHook}
        searchKeys={['value']}
        useGetFn={useTaccCommunicationMethodsGetFn}
        useFilterFn={useTAccCommunicationMethodsFilterFn}
        checkboxPotentialFieldPaths={potentialFieldPaths}
      >
        {label}
        {tAccIdGesEvalTypeElementLabel}
      </CheckboxListInput>
    </>
  );
};
