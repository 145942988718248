import { useContext, useMemo } from 'react';
import { AccountsOrdersPendingParam } from '../type';
import { accountsOrdersPendingParamsContext } from '../context';
import { useTAccId } from '../../../shared';
import { useIsConnected } from '../../../../../../Components/Auth';
import { stringifyParams } from '../../../shared/utils/makeUrl';

export const useAccounOrdersPendingDefaultParams = (params: Partial<AccountsOrdersPendingParam>): Partial<AccountsOrdersPendingParam> | null => {
  const {
    'T_ACC.ID': contextTAccId,
  } = useContext(accountsOrdersPendingParamsContext);
  const tAccIdString = useTAccId();
  const currentTAccId = tAccIdString ? parseInt(tAccIdString, 10) : undefined;
  const tAccId = params['T_ACC.ID'] ?? contextTAccId ?? currentTAccId;
  const isConnected = useIsConnected();
  const defaultParams = useMemo(() => ({
    'T_ACC.ID': tAccId,
  }), [tAccId]);
  if (!tAccId || !isConnected) {
    return null;
  }
  return defaultParams;
};

export const useAccounOrdersPendingStringifiedParams = () => {
  const contextParams = useAccounOrdersPendingDefaultParams({});
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
