import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldTransfomApiByOpbFordId } from '../api';
import { useLmlGetPositionsTitleCase } from '../../../positions';
import { useLinguiLocale } from '../../../../../../../Components/useLinguiLocale';

export const useAccountOrderLml2Description = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const locale = useLinguiLocale();
  const titleCaser = useLmlGetPositionsTitleCase(locale);
  const response = useAccountOrderFieldTransfomApiByOpbFordId({
    opbFordDId,
    field: 'S_ACC.LML2_DESCRIPTION',
    transformFunction: titleCaser,
  });
  return response;
};
