import { GetBondsPromotionsResponse } from '@goldwasserexchange/actor/rest-services';
import { Bond } from '@goldwasserexchange/common/lib/bond';
import { parseActorDate } from '../../../../shared/utils/parseDates';
import { BondResponse } from '../type';
import { memoCreateBond, memoGetPrice, memoGetYield } from './memoCreateBond';
import { transformSAccJrsFreqToBondFrequency } from './transformSAccJrsFreqToBondFrequency';
import { addBusinessDays } from 'date-fns';

export function makeGrossBondCalculator(bondsBond: undefined): undefined
export function makeGrossBondCalculator(bondsBond: BondResponse | GetBondsPromotionsResponse): Bond
export function makeGrossBondCalculator(bondsBond: BondResponse | GetBondsPromotionsResponse | undefined): Bond | undefined
export function makeGrossBondCalculator(bondsBond: BondResponse | GetBondsPromotionsResponse | undefined): Bond | undefined {
  if (bondsBond == null) {
    return undefined;
  }
  const {
    'S_ACC.INT_RATE': rate,
    'S_ACC.OBL_END_DATE': maturity,
    'S_ACC.OBL_END_PRICE': redemption,
    'S_ACC_JRS.FREQ': sAccJrsFreq,
    'S_ACC.ISSUE_DATE': issue,
    'S_ACC.OBL_FIRST_CPS': firstCoupon,
  } = bondsBond;
  const frequency = transformSAccJrsFreqToBondFrequency(sAccJrsFreq);
  if (frequency == null) {
    return undefined;
  }
  const bondCalculator = memoCreateBond({
    rate: rate / 100,
    maturity: parseActorDate(maturity),
    redemption,
    frequency,
    issue: parseActorDate(issue),
    firstCoupon: firstCoupon ? parseActorDate(firstCoupon) : undefined,
  });
  return bondCalculator;
}

export const makeGrossBondYieldCalculator = (bondsBond: BondResponse | GetBondsPromotionsResponse | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const bond = makeGrossBondCalculator(bondsBond);
  const getYield = (price: number) => memoGetYield(bond, price);
  return getYield;
};

export const makeGrossBondPriceCalculator = (bondsBond: BondResponse | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const bond = makeGrossBondCalculator(bondsBond);
  const getYield = (yld: number) => memoGetPrice(bond, yld);
  return getYield;
};

function getSettlementDate(isin: string): Date {
  if (isin.startsWith('US')) {
    return addBusinessDays(new Date(), 1);
  }
  return addBusinessDays(new Date(), 2);
}

export const makeGrossBondCalculatorWithSettlment = (bondsBond: BondResponse | undefined): Bond | undefined => {
  if (bondsBond == null) {
    return undefined;
  }
  const {
    'S_ACC.INT_RATE': rate,
    'S_ACC.OBL_END_DATE': maturity,
    'S_ACC.OBL_END_PRICE': redemption,
    'S_ACC_JRS.FREQ': sAccJrsFreq,
    'S_ACC.ISSUE_DATE': issue,
    'S_ACC.OBL_FIRST_CPS': firstCoupon,
    'S_ACC.ISIN': isin,

  } = bondsBond;
  const settlement = getSettlementDate(isin);
  const frequency = transformSAccJrsFreqToBondFrequency(sAccJrsFreq);
  if (frequency == null) {
    return undefined;
  }
  const bondCalculator = memoCreateBond({
    rate: rate / 100,
    maturity: parseActorDate(maturity),
    redemption,
    frequency,
    issue: parseActorDate(issue),
    firstCoupon: firstCoupon ? parseActorDate(firstCoupon) : undefined,
    settlement,
  });
  return bondCalculator;
};
