import {
  Update,
} from 'history';
import {
  languageRouteDefinition,
} from './router';
import {
  splitPathname,
} from '../../utils';
import {
  isLocale,
} from './utils';

export const languageRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const language = splittedPathname.at(0);
  if (language && isLocale(language)) {
    const { importFn } = languageRouteDefinition[language];
    if (importFn != null && typeof importFn === 'function') {
      importFn();
    }
  }
};
