import { BondResponse } from '../../type';
import { useBondsBondTransformApi } from '../api';

const transformFn = (bond: BondResponse | undefined) => {
  if (bond == null) {
    return null;
  }
  const exRate = bond?.['EXRATE_HIS.EXRATE_GES'];
  const quotMul = bond?.['S_MARKET.QUOT_MULT'];
  const coupure = Math.max(1000, quotMul);
  const maxEur = 300_000;
  const maxCurrency = maxEur / (exRate ?? 1);
  const rangeMax = Math.ceil(maxCurrency / coupure) * coupure;
  return rangeMax;
};

export const useBondsBondMaxQuantity = () => {
  const response = useBondsBondTransformApi(transformFn);
  return response;
};
