import { msg } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { ValidSections } from '../../router/sections/constants';

export const SECTION_TITLES: Record<ValidSections | 'not-found', MessageDescriptor> = {
  [ValidSections.HOME]: msg`Accueil`,
  [ValidSections.ONLINE]: msg`Mon Compte`,
  [ValidSections.NEWS]: msg`Actualités`,
  [ValidSections.BONDS]: msg`Obligations`,
  [ValidSections.CONTACT]: msg`Contact`,
  [ValidSections.ONBOARDING]: msg`Devenir client`,
  [ValidSections.GLOBAL_PLUS_ACCOUNT]: msg`Compte Global+`,
  [ValidSections.GOLD_ACCOUNT]: msg`Compte Gold`,
  [ValidSections.PREMIUM_ACCOUNT]: msg`Compte Premium`,
  [ValidSections.LEGAL]: msg`Informations légales`,
  [ValidSections.SECURITY]: msg`Sécurité`,
  [ValidSections.SIGN_UP]: msg`Activer votre accès en ligne`,
  [ValidSections.UPLOAD]: msg`Fournir des documents`,
  [ValidSections.ORDER]: msg`Passer un ordre`,
  'not-found': msg`Page introuvable`,
};
