/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, {
  useEffect, PropsWithChildren, useRef, useContext,
} from 'react';
import isNil from 'ramda/es/isNil';
import ouiStyle from '@goldwasserexchange/oui-style';
import ouiDom from '@goldwasserexchange/oui-dom';
import {
  makeName, UncastedDataStructure, UncastedOnboarding, UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import { Check, X } from 'react-feather';
import {
  useSetSelected, useIsSelected, SelectionListContext, usePrimaryKey,
} from '../BaseComponents/List';
import {
  usePushFieldArray, FormArrayFilterFn, useFormArrayWithFilter,
} from '../Form/utils';
import { Scrollable } from '../Scrollable';
import { useLayout } from '../Layout/helpers';
import { ClearFixSection } from '../clearfix';
import { useTouchedContext } from '../../OUI/Form';
import {
  useGetFilteredFieldPaths,
} from '../tAdd';
import { T_ADD_TABLE_NAME } from '../tAdd/data/types';
import { useErrors } from '../../OUI/Form/context';
import { icons, IconName } from '../Icons';

const cardIconStyle: ouiStyle.InStyle = {
  borderRadius: 'full',
  overflow: 'hidden',
  borderColor: ouiStyle.Constants.Colors.primary,
  borderWidth: 'simple',
  borderStyle: 'solid',
  padding: 'simple',
  icon: true,
  color: ouiStyle.Constants.Colors.primary,
  display: ouiStyle.Constants.DisplayProp.block,
  lines: 'full',
  widthLines: 'full',
  marginHorizontal: 'auto',
};

const cardIconCss = ouiStyle.makeCss(cardIconStyle);

const CardIcon = ({ iconName, selected }) => {
  const Component = icons[iconName];
  return (
    <Component
      size="3.375rem"
      css={cardIconCss}
      style={{
        color: selected ? ouiStyle.Constants.Colors.inverted : ouiStyle.Constants.Colors.primary,
        borderColor: selected ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.primary,
        backgroundColor: selected ? ouiStyle.Constants.Colors.accent : ouiStyle.Constants.Colors.inverted,
        maxWidth: '3.375rem',
      }}
    />
  );
};

const cardLegendStyle: ouiStyle.InStyle = {
  textAlign: 'center',
  padding: 'simple',
  lines: 4,
  widthLines: 'full',
};

const cardLegendCss = ouiStyle.makeCss(cardLegendStyle);

const CardLegend = ({ children }) => (
  <div
    css={cardLegendCss}
  >
    {children}
  </div>
);

const iconContainerStyle: ouiStyle.InStyle = {
  position: 'relative',
  marginHorizontal: 'simple',
};

// eslint-disable-next-line default-param-last
const useIconContainerCss = (bordered = false, float: ouiStyle.InStyle['float']) => {
  const iconContainerCss = React.useMemo(() => {
    const baseCss = ouiStyle.makeCss({
      ...iconContainerStyle,
      float,
    });
    if (bordered) {
      return {
        ...baseCss,
        ...ouiStyle.makeCss({
          borderStyle: 'solid',
          borderWidth: 'simple',
          borderColor: ouiStyle.Constants.Colors.primary,
        }),
      };
    }
    return baseCss;
  }, [bordered, float]);
  return iconContainerCss;
};

export const IconWithLegend = (props): JSX.Element => {
  const {
    onClick,
    children,
    iconName,
    selected,
    innerRef,
    bordered,
    float,
    style,
  } = props;
  const iconContainerCss = useIconContainerCss(bordered, float);
  return (
    <div
      ref={innerRef}
      css={iconContainerCss}
      style={style}
    >
      <ouiDom.Button.Button
        onClick={onClick}
        type="button"
        widthLines="full"
        borderTopColor={ouiStyle.Constants.Colors.transparent}
        borderBottomColor={ouiStyle.Constants.Colors.transparent}
        borderLeftColor={ouiStyle.Constants.Colors.transparent}
        borderRightColor={ouiStyle.Constants.Colors.transparent}
        whiteSpace={ouiStyle.Constants.WhiteSpaceProperty.normal}
        fontWeight={ouiStyle.Constants.FontWeightProp.normal}
      >
        <CardIcon
          iconName={iconName}
          selected={selected}
        />
        <CardLegend>
          {children}
        </CardLegend>
      </ouiDom.Button.Button>
    </div>
  );
};

const cardIconContainerStyle: ouiStyle.InStyle = {
  position: 'absolute',
  top: `calc(1.5rem + ${ouiStyle.Constants.sizes.space}rem)`,
  left: 'calc((10.625rem + 0.75rem) / 2)',
};

const cardIconContainerCss = ouiStyle.makeCss(cardIconContainerStyle);

const onboardingPath: keyof Pick<UncastedDataStructure, 'onboarding'> = 'onboarding';

const usersPath: keyof Pick<UncastedOnboarding, 'users'> = 'users';

export const IconWithLegendSelector = (props): JSX.Element => {
  const setSelected = useSetSelected();
  const isSelected = useIsSelected();
  const primaryKey = usePrimaryKey();
  const {
    iconName,
    style,
    scrollTo,
    clientWidth,
    elementWidth,
    children,
    whiteListColumns = [],
    blackListColumns = [],
    float,
  } = props;
  const touched = useTouchedContext();
  const errors = useErrors();
  const visibleFieldPaths = useGetFilteredFieldPaths(whiteListColumns, blackListColumns, makeName(onboardingPath, usersPath, primaryKey), T_ADD_TABLE_NAME)
    .filter((fieldPath) => fieldPath != null);
  const isTouched = visibleFieldPaths.every((fieldPath) => touched.nested(fieldPath).get() === true);
  const isValid = visibleFieldPaths.every((fieldPath) => touched.nested(fieldPath).get() !== true
    || (touched.nested(fieldPath).get() === true && (errors.nested(fieldPath).get() || []).length === 0));
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isSelected && ref.current) {
      const middle = (clientWidth - (elementWidth * 16)) / 2;
      scrollTo(ref.current.offsetLeft - (middle > 0 ? middle : clientWidth));
    }
  }, [isSelected]);
  return (
    <IconWithLegend
      innerRef={ref}
      onClick={setSelected}
      iconName={iconName}
      style={style}
      selected={isSelected}
      bordered
      float={float}
    >
      {children}
      {isTouched
        && (
          <div css={cardIconContainerCss}>
            {isValid ? (
              <Check style={{
                color: ouiStyle.Constants.Colors.success,
              }}
              />
            ) : (
              <X style={{
                color: ouiStyle.Constants.Colors.danger,
              }}
              />
            )}
          </div>
        )}
    </IconWithLegend>
  );
};

type ScrollableIconWithLegendSelectProps = {
  iconName: IconName,
  height: string,
  elementWidth: number,
  gap: number,
  data: any,
  whiteListColumns?: string[],
  blackListColumns?: string[],
}

export const ScrollableIconWithLegendSelect = (props: PropsWithChildren<ScrollableIconWithLegendSelectProps>): JSX.Element => {
  const {
    data,
    iconName,
    height,
    elementWidth,
    gap,
    whiteListColumns = [],
    blackListColumns = [],
    children,
  } = props;
  return (
    <Scrollable
      data={data}
      elementWidth={elementWidth + gap}
      height={height}
    >
      {(_, clientWidth, ___, width, ____, scrollTo): JSX.Element => (
        <IconWithLegendSelector
          iconName={iconName}
          scrollTo={scrollTo}
          clientWidth={clientWidth}
          elementWidth={width}
          float="left"
          whiteListColumns={whiteListColumns}
          blackListColumns={blackListColumns}
          style={{
            width: `${width - gap}rem`, marginRight: `${gap / 2}rem`, marginLeft: `${gap / 2}rem`, display: 'inline-block',
          }}
        >
          {children}
        </IconWithLegendSelector>
      )}
    </Scrollable>
  );
};

type ScrollableIconWithLegendAddButtonProps = {
  iconName: IconName,
  addValue: any,
}

export const ScrollableIconWithLegendAddButton = (props: PropsWithChildren<ScrollableIconWithLegendAddButtonProps>) => {
  const {
    iconName, addValue, children,
  } = props;
  const onClick = usePushFieldArray(addValue);
  return (
    <IconWithLegend
      onClick={onClick}
      iconName={iconName}
    >
      {children}
    </IconWithLegend>
  );
};

export type ScrollableIconWithLegendSelectWithAddProps = { addIconName: IconName } & ScrollableIconWithLegendSelectProps & {
  addValue: any,
  filterFn?: FormArrayFilterFn | ((tAdd: UncastedPhysicalTAdd) => boolean),
  hasAdd: boolean,
  whiteListColumns?: string[],
  blackListColumns?: string[],
}

export const ScrollableIconWithLegendSelectAndAdd = (props: PropsWithChildren<Omit<ScrollableIconWithLegendSelectWithAddProps, 'parentName' | 'data'>>): JSX.Element | null => {
  const {
    filterFn,
    iconName,
    addIconName,
    height,
    elementWidth,
    gap,
    addValue,
    hasAdd = true,
    whiteListColumns = [],
    blackListColumns = [],
    children,
  } = props;
  const { legend, addText } = useLayout(children);
  const data = useFormArrayWithFilter(filterFn);
  const [selected, setSelected] = useContext(SelectionListContext);
  const { length } = data;
  useEffect(() => {
    if (length > 0 && isNil(selected)) {
      setSelected(data[0])();
    }
  }, [length, selected]);
  return data.length > 0
    ? (
      <ClearFixSection>
        <main style={{
          float: 'left',
          width: `calc(100% - (7.8125rem + (2 * ${ouiStyle.Constants.sizes.space}rem)))`,
        }}
        >
          <ScrollableIconWithLegendSelect
            data={data}
            iconName={iconName}
            height={height}
            elementWidth={elementWidth}
            gap={gap}
            whiteListColumns={whiteListColumns}
            blackListColumns={blackListColumns}
          >
            {legend}
          </ScrollableIconWithLegendSelect>
        </main>
        {hasAdd && (
          <aside style={{
            float: 'left',
            width: `calc(7.8125rem + (2 * ${ouiStyle.Constants.sizes.space}rem))`,
          }}
          >
            <ScrollableIconWithLegendAddButton iconName={addIconName} addValue={addValue}>
              {addText}
            </ScrollableIconWithLegendAddButton>
          </aside>
        )}
      </ClearFixSection>
    )
    : null;
};
