import { Update } from 'history';
import { MessageDescriptor } from '@lingui/core';
import { HeadProps } from '../type';
import { splitPathname } from '../../utils';
import { SECTION_TITLES } from './titles';
import { ValidSections } from '../../router';
import { createHrefs } from '../utils';
import { makeAccountsSectionHeadProps } from './accounts';
import { getTitleFromMessageDescriptor } from '../utils/getTitle';
import { makeOnboardingSectionHeadProps } from './onboarding';
import { makeBondsSectionHeadProps } from './bonds';
import { makeNewsSectionHeadProps } from './news/makeHeadProps';

export const makeSectionHeadProps = (currentUpdate: Update, currentHeadProps: HeadProps) => {
  const headProps = {
    ...currentHeadProps,
  };
  const {
    location,
  } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const {
    language,
  } = headProps;
  const section = (splittedPathname.at(1) ?? '') as ValidSections;
  const titleMessageDescriptor = (SECTION_TITLES[section] ?? SECTION_TITLES['not-found']) as MessageDescriptor;
  headProps.title = getTitleFromMessageDescriptor(titleMessageDescriptor, language);
  switch (section) {
    case ValidSections.ONLINE: {
      return makeAccountsSectionHeadProps(currentUpdate, headProps);
    }
    case ValidSections.ONBOARDING: {
      return makeOnboardingSectionHeadProps(currentUpdate, headProps);
    }
    case ValidSections.BONDS: {
      return makeBondsSectionHeadProps(currentUpdate, headProps);
    }
    case ValidSections.NEWS: {
      return makeNewsSectionHeadProps(currentUpdate, headProps);
    }
    default: {
      const {
        href,
        frHref,
        nlHref,
      } = createHrefs(currentUpdate);
      headProps.href = href;
      headProps.frHref = frHref;
      headProps.nlHref = nlHref;
      return headProps;
    }
  }
};
