import { Update } from 'history';
import { AccountsSectionRouteDefinition } from './router';
import { splitPathname } from '../../../utils';
import { getValidAccountsSection } from './utils';
import { accountsOrdersRouting } from './orders';

export const accountsRouting = (currentUpdate: Update) => {
  const { location } = currentUpdate;
  const {
    pathname,
  } = location;
  const splittedPathname = splitPathname(pathname);
  const accountsSection = splittedPathname.at(2);
  const validAccountsSection = getValidAccountsSection(accountsSection);
  const {
    importFn,
  } = AccountsSectionRouteDefinition[validAccountsSection];
  if (importFn && typeof importFn === 'function') {
    importFn();
  }
  switch (validAccountsSection) {
    case 'orders':
      accountsOrdersRouting(currentUpdate);
      break;
    default:
      break;
  }
};
