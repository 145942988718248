import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId } from '../api';

export const useAccountOrderOpbFordCreateAt = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'OPB_FORD.CREATE_AT',
  });
  return response;
};
