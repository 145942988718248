import ouiBase from '@goldwasserexchange/oui';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { makeBondSlugTransformFn } from '../../../shared/makeBondSlug';
import { useBondSameIssuerTransformApiBySAccId } from '../api';

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useBondSameIssuerSlug = () => {
  const sAccId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const locale = useGetDateFnsLocale();
  const transformFn = makeBondSlugTransformFn({
    intRateFormatter,
    locale,
  });
  const response = useBondSameIssuerTransformApiBySAccId({
    transformFunction: transformFn,
    sAccId,
  });
  return response;
};
